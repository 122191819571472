// Dependencies
import React, { useState } from "react";

// Components
import DocOk from "../components/icons/docOk";
import DocDownload from "../components/icons/docDownload";
import Print from "../components/icons/print";
import Dollar from "../components/icons/dollar";
import Close from "../components/icons/close";
import Question from "../components/icons/question";
import Check from "../components/icons/check";
import User from "../components/icons/user";
import Home from "../components/icons/home";
import Search from "../components/icons/search";
import Email from "../components/icons/email";
import RoundedArrow from "../components/icons/roundedArrow";
import Play from "../components/icons/play";
import Notification from "../components/icons/notification";
import Messages from "../components/icons/messages";
import Folder from "../components/icons/folder";
import Logo from "../components/icons/logo";
import LogoPro from "../components/icons/logoPro";
import LogoInsurance from "../components/icons/logoInsurance";
import ChevronArrow from "../components/icons/chevronArrow";
import Off from "../components/icons/menuIcons/off";
import Menu from "../components/icons/menuIcons/menu";
import Chart from "../components/icons/menuIcons/chart";
import Diagram from "../components/icons/menuIcons/diagram";
import Info from "../components/icons/menuIcons/info";
import InfoIcon from "../components/icons/infoIcon";
import Learning from "../components/icons/menuIcons/learning";
import OwnerShip from "../components/icons/menuIcons/ownerShip";
import Gear from "../components/icons/toolsIcons/gear";
import Garbage from "../components/icons/toolsIcons/garbage";
import Download from "../components/icons/toolsIcons/download";
import Filter from "../components/icons/toolsIcons/filter";
import Pencil from "../components/icons/toolsIcons/pencil";
import Star from "../components/icons/toolsIcons/star";
import Save from "../components/icons/toolsIcons/save";
import Help from "../components/icons/toolsIcons/help";
import ClosedFolder from "../components/icons/docIcons/closedFolder";
import DocWord from "../components/icons/docIcons/docWord";
import DocPdf from "../components/icons/docIcons/docPdf";
import DocExcel from "../components/icons/docIcons/docExcel";
import DocPowerPoint from "../components/icons/docIcons/docPowerPoint";
import DocImage from "../components/icons/docIcons/docImage";
import AlertWarning from "../components/icons/alertIcons/alertWarning";
import AlertInfo from "../components/icons/alertIcons/alertInfo";
import AlertMoney from "../components/icons/alertIcons/alertMoney";
import Balance from "../components/icons/sectionIcons/balance";
import Performance from "../components/icons/sectionIcons/performance";
import LastActivity from "../components/icons/sectionIcons/lastActivity";
import Notifications from "../components/icons/sectionIcons/notifications";
import News from "../components/icons/sectionIcons/news";
import Training from "../components/icons/sectionIcons/training";
import Structure from "../components/icons/sectionIcons/structure";
import OwnershipBig from "../components/icons/sectionIcons/ownership";
import Plus from "../components/icons/plus";
import SingleArrow from "../components/icons/singleArrow";
import Eye from "../components/icons/eye";
import Box from "../components/icons/box";
import Edit from "../components/icons/edit";
import DocNew from "../components/icons/docIcons/docNew";
import HandPointer from "../components/icons/handPointer";
import Camera from "../components/icons/toolsIcons/camera";
import LogoB from "../components/icons/logo_B";
import Minus from "../components/icons/minus";
import Lock from "../components/icons/lock";
import LogoAddepar from "../components/icons/logoAddepar";
import Link from "../components/icons/link";
import Unlink from "../components/icons/unlink";
import LinkChain from "../components/icons/linkChain";
import Construction from "../components/icons/construction";
import Unlock from "../components/icons/unlock";
import Assign from "../components/icons/assign";
import FolderBig from "../components/icons/folderBig";
import BoxEmpty from "../components/icons/boxEmpty";
import OpenApp from "../components/icons/openApp";
import PlusThin from "../components/icons/plusThin";
import BankAccount from "../components/icons/assets/bankAccount";
import ReceivableAccount from "../components/icons/assets/receivableAccount";
import RealEstate from "../components/icons/assets/realEstate";
import PrivateEquity from "../components/icons/assets/privateEquity";
import Vehicle from "../components/icons/assets/vehicle";
import Boat from "../components/icons/assets/boat";
import Airplane from "../components/icons/assets/airplane";
import Watch from "../components/icons/assets/watch";
import Art from "../components/icons/assets/art";
import Other from "../components/icons/assets/other";
import LifeInsurance from "../components/icons/assets/lifeInsurance";
import Liability from "../components/icons/assets/liability";
import Trust from "../components/icons/assets/trust";
import Corporation from "../components/icons/assets/corporation";
import InvestmentAccount from "../components/icons/assets/investmentAccount";
import BeneficiaryAccount from "../components/icons/assets/beneficiaryAccount";
import DocExcelSquare from "../components/icons/docIcons/docExcelSquare";
import DocPdfSquare from "../components/icons/docIcons/docPdfSquare";
import DocWordSquare from "../components/icons/docIcons/docWordSquare";
import DocPowerPointSquare from "../components/icons/docIcons/docPowerPointSquare";
import DocImageSquare from "../components/icons/docIcons/docImageSquare";
import DocFolderSquare from "../components/icons/docIcons/docFolderSquare";
import ChangeUser from "../components/icons/changeUser";
import Tenant from "../components/icons/tenant";
import InfoTooltip from "../components/icons/infoTooltip";
import Fullscreen from "../components/icons/fullscreen";
import ZoomIn from "../components/icons/zoomIn";
import ZoomOut from "../components/icons/zoomOut";
import ZoomFit from "../components/icons/zoomFit";
import AutoLayout from "../components/icons/AutoLayout";
import Upload from "../components/icons/upload";

const COLOR = "#408197";

const renderIcon = ({ props }) => {
  switch (props.name) {
    case "FolderBig":
      return (
        <FolderBig
          width={props.width}
          height={props.height}
          color={props.color}
          style={props.style}
        />
      );
    case "Close":
      return (
        <Close
          width={props.width}
          height={props.height}
          color={props.color}
          close={props.close}
        />
      );
    case "DocOk":
      return (
        <DocOk
          width={props.width}
          height={props.height}
          color={props.color}
          bgColor={props.bgColor}
        />
      );
    case "DocDownload":
      return (
        <DocDownload
          width={props.width}
          height={props.height}
          color={props.color}
          bgColor={props.bgColor}
          arrowColor={props.arrowColor}
          arrowCircleColor={props.arrowCircleColor}
          arrowCircleBgColor={props.arrowCircleBgColor}
        />
      );
    case "Print":
      return (
        <Print width={props.width} height={props.height} color={props.color} />
      );
    case "Dollar":
      return (
        <Dollar width={props.width} height={props.height} color={props.color} />
      );
    case "Question":
      return (
        <Question
          width={props.width}
          height={props.height}
          color={props.color}
          close={props.close}
        />
      );
    case "Check":
      return (
        <Check width={props.width} height={props.height} color={props.color} />
      );
    case "Home":
      return (
        <Home width={props.width} height={props.height} color={props.color} />
      );
    case "Search":
      return (
        <Search width={props.width} height={props.height} color={props.color} />
      );
    case "RoundedArrow":
      return (
        <RoundedArrow
          width={props.width}
          height={props.height}
          color={props.color}
          orientation={props.orientation}
        />
      );
    case "Play":
      return (
        <Play
          width={props.width}
          height={props.height}
          color={props.color}
          orientation={props.orientation}
          bgColor={props.bgColor}
        />
      );
    case "Notification":
      return (
        <Notification
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "Messages":
      return (
        <Messages
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "Folder":
      return (
        <Folder width={props.width} height={props.height} color={props.color} />
      );
    case "Logo":
      return (
        <Logo width={props.width} height={props.height} color={props.color} />
      );
    case "LogoPro":
      return (
        <LogoPro width={props.width} height={props.height} color={props.color} />
      );
    case "LogoInsurance":
      return (
        <LogoInsurance
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "ChevronArrow":
      return (
        <ChevronArrow
          width={props.width}
          height={props.height}
          color={props.color}
          orientation={props.orientation}
          bgColor={props.bgColor}
          noWrapper={props.noWrapper}
        />
      );
    case "Off":
      return (
        <Off width={props.width} height={props.height} color={props.color} />
      );
    case "Menu":
      return (
        <Menu width={props.width} height={props.height} color={props.color} />
      );
    case "Chart":
      return (
        <Chart width={props.width} height={props.height} color={props.color} />
      );
    case "Ownership":
      return (
        <OwnerShip
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "Diagram":
      return (
        <Diagram
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "Info":
      return (
        <Info width={props.width} height={props.height} color={props.color} />
      );
    case "InfoIcon":
      return (
        <InfoIcon
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "Learning":
      return (
        <Learning
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "Gear":
      return (
        <Gear
          width={props.width}
          height={props.height}
          color={props.color}
          stroke={props.stroke}
          strokeWidth={props.strokeWidth}
        />
      );
    case "Garbage":
      return (
        <Garbage
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "Download":
      return (
        <Download
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "Filter":
      return (
        <Filter width={props.width} height={props.height} color={props.color} />
      );
    case "Pencil":
      return (
        <Pencil width={props.width} height={props.height} color={props.color} />
      );
    case "Star":
      return (
        <Star width={props.width} height={props.height} color={props.color} />
      );
    case "Save":
      return (
        <Save width={props.width} height={props.height} color={props.color} />
      );
    case "Help":
      return (
        <Help width={props.width} height={props.height} color={props.color} />
      );
    case "ClosedFolder":
      return (
        <ClosedFolder
          width={props.width}
          height={props.height}
          color={props.color}
          bgColor={props.bgColor}
          noWrapper={props.noWrapper}
        />
      );
    case "DocWord":
      return (
        <DocWord
          width={props.width}
          height={props.height}
          color={props.color}
          bgColor={props.bgColor}
          noWrapper={props.noWrapper}
        />
      );
    case "DocPdf":
      return (
        <DocPdf
          width={props.width}
          height={props.height}
          color={props.color}
          bgColor={props.bgColor}
          noWrapper={props.noWrapper}
        />
      );
    case "DocExcel":
      return (
        <DocExcel
          width={props.width}
          height={props.height}
          color={props.color}
          bgColor={props.bgColor}
          noWrapper={props.noWrapper}
        />
      );
    case "DocPowerPoint":
      return (
        <DocPowerPoint
          width={props.width}
          height={props.height}
          color={props.color}
          bgColor={props.bgColor}
          noWrapper={props.noWrapper}
        />
      );
    case "DocImage":
      return (
        <DocImage
          width={props.width}
          height={props.height}
          color={props.color}
          bgColor={props.bgColor}
          noWrapper={props.noWrapper}
        />
      );
    case "AlertWarning":
      return (
        <AlertWarning
          width={props.width}
          height={props.height}
          color={props.color}
          bgColor={props.bgColor}
          noWrapper={props.noWrapper}
        />
      );
    case "AlertInfo":
      return (
        <AlertInfo
          width={props.width}
          height={props.height}
          color={props.color}
          bgColor={props.bgColor}
          noWrapper={props.noWrapper}
        />
      );
    case "AlertMoney":
      return (
        <AlertMoney
          width={props.width}
          height={props.height}
          color={props.color}
          bgColor={props.bgColor}
          noWrapper={props.noWrapper}
        />
      );
    case "Balance":
      return (
        <Balance
          width={props.width}
          height={props.height}
          colorPrimary={props.colorPrimary}
          colorSecondary={props.colorSecondary}
        />
      );
    case "Performance":
      return (
        <Performance
          width={props.width}
          height={props.height}
          colorPrimary={props.colorPrimary}
          colorSecondary={props.colorSecondary}
        />
      );
    case "LastActivity":
      return (
        <LastActivity
          width={props.width}
          height={props.height}
          colorPrimary={props.colorPrimary}
          colorSecondary={props.colorSecondary}
          strokeWidth={props.strokeWidth}
        />
      );
    case "Notifications":
      return <Notifications width={props.width} height={props.height} />;
    case "News":
      return (
        <News
          width={props.width}
          height={props.height}
          colorPrimary={props.colorPrimary}
          colorSecondary={props.colorSecondary}
          strokeWidth={props.strokeWidth}
        />
      );
    case "Training":
      return (
        <Training
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "Structure":
      return (
        <Structure
          width={props.width}
          height={props.height}
          colorPrimary={props.colorPrimary}
          colorSecondary={props.colorSecondary}
        />
      );
    case "OwnershipBig":
      return (
        <OwnershipBig
          width={props.width}
          height={props.height}
          colorPrimary={props.colorPrimary}
          colorSecondary={props.colorSecondary}
        />
      );
    case "Plus":
      return (
        <Plus
          width={props.width}
          height={props.height}
          color={props.color}
          bgColor={props.bgColor}
          noWrapper={props.noWrapper}
          close={props.close}
        />
      );
    case "SingleArrow":
      return (
        <SingleArrow
          width={props.width}
          height={props.height}
          color={props.color}
          orientation={props.orientation}
        />
      );
    case "Eye":
      return (
        <Eye
          width={props.width}
          height={props.height}
          color={props.color}
          slashed={props.slashed}
        />
      );
    case "Box":
      return (
        <Box
          width={props.width}
          height={props.height}
          color={props.color}
          bgColor={props.bgColor}
        />
      );
    case "Assign":
      return (
        <Assign
          width={props.width}
          height={props.height}
          color={props.color}
          bgColor={props.bgColor}
        />
      );
    case "Edit":
      return (
        <Edit width={props.width} height={props.height} color={props.color} />
      );
    case "DocNew":
      return (
        <DocNew width={props.width} height={props.height} color={props.color} />
      );
    case "HandPointer":
      return (
        <HandPointer
          width={props.width}
          height={props.height}
          color={props.color}
          className={props.className}
        />
      );
    case "Camera":
      return (
        <Camera width={props.width} height={props.height} color={props.color} />
      );
    case "LogoB":
      return (
        <LogoB width={props.width} height={props.height} color={props.color} />
      );
    case "Minus":
      return (
        <Minus width={props.width} height={props.height} color={props.color} />
      );
    case "Lock":
      return (
        <Lock width={props.width} height={props.height} color={props.color} />
      );
    case "LogoAddepar":
      return (
        <LogoAddepar
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "Link":
      return (
        <Link width={props.width} height={props.height} color={props.color} onClick={props.onClick}/>
      );
    case "Unlink":
      return (
        <Unlink width={props.width} height={props.height} color={props.color} />
      );

    case "LinkChain":
      return (
        <LinkChain
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "Construction":
      return (
        <Construction
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "Email":
      return (
        <Email width={props.width} height={props.height} color={props.color} />
      );
    case "Unlock":
      return (
        <Unlock width={props.width} height={props.height} color={props.color} />
      );
    case "User":
      return (
        <User width={props.width} height={props.height} color={props.color} />
      );
    case "Tenant":
      return (
        <Tenant width={props.width} height={props.height} color={props.color} />
      );
    case "BoxEmpty":
      return (
        <BoxEmpty
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "OpenApp":
      return (
        <OpenApp
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "PlusThin":
      return (
        <PlusThin
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "BankAccount":
      return (
        <BankAccount
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "ReceivableAccount":
      return (
        <ReceivableAccount
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "RealEstate":
      return (
        <RealEstate
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "PrivateEquity":
      return (
        <PrivateEquity
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "Vehicle":
      return (
        <Vehicle
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "Boat":
      return (
        <Boat width={props.width} height={props.height} color={props.color} />
      );
    case "Airplane":
      return (
        <Airplane
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "Watch":
      return (
        <Watch width={props.width} height={props.height} color={props.color} />
      );
    case "Art":
      return (
        <Art width={props.width} height={props.height} color={props.color} />
      );
    case "Other":
      return (
        <Other width={props.width} height={props.height} color={props.color} />
      );
    case "LifeInsurance":
      return (
        <LifeInsurance
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "Liability":
      return (
        <Liability
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "Trust":
      return (
        <Trust width={props.width} height={props.height} color={props.color} />
      );
    case "Corporation":
      return (
        <Corporation
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "InvestmentAccount":
    case "FinancialAssetAssociated":
      return (
        <InvestmentAccount
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "BeneficiaryAccount":
      return (
        <BeneficiaryAccount
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "DocExcelSquare":
      return (
        <DocExcelSquare
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "DocPdfSquare":
      return (
        <DocPdfSquare
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "DocWordSquare":
      return (
        <DocWordSquare
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "DocPowerPointSquare":
      return (
        <DocPowerPointSquare
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "DocImageSquare":
      return (
        <DocImageSquare
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "DocFolderSquare":
      return (
        <DocFolderSquare
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "ChangeUser":
      return (
        <ChangeUser
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "InfoTooltip":
      return (
        <InfoTooltip
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "Fullscreen":
      return (
        <Fullscreen width={props.width} height={props.height} color={props.color} />
      );
    case "ZoomIn":
      return (
        <ZoomIn width={props.width} height={props.height} color={props.color} />
      );
    case "ZoomOut":
      return (
        <ZoomOut
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "ZoomFit":
      return (
        <ZoomFit
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "AutoLayout":
      return (
        <AutoLayout
          width={props.width}
          height={props.height}
          color={props.color}
        />
      );
    case "Upload":
      return (
        <Upload width={props.width} height={props.height} color={props.color} />
      );
    default:
      return props.name;
  }
};

export default renderIcon;
