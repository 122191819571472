import { useRef, useEffect } from "react";
import PermissionCircle from "./permissionCircle";

const HeroCard = ({ item, lang, setTooltip }) => {

  const heroCardRef = useRef(null);

  return (
    <div 
      ref={heroCardRef}
      className="card small in-grid"
      onClick={() => setTooltip(item, heroCardRef)}
      style={{cursor: item.tooltip ? 'pointer' : 'default'}}
    >
      <p className="headingH3 o-color--grey-dark">
        {Object.values(item)[0].amount}
      </p>
      <p className="card-text small o-color--blue-dark">{Object.values(item)[0].title}</p>
      <PermissionCircle item={Object.values(item)[0]} />

    </div>
  )
}

export default HeroCard
