// Dependencies
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Components
import Icon from "./icons/";


const Logo = (props) => {
  useEffect(() => {
    isHome();
  }, [props.url]);

  const isHome = () => {
    return window.location.pathname === "/home";
  };

  const getDimension = () => {

    if(isHome()){
      if(props.real_user_insurance){
        return {width: '125px', height: '63px'}
      }else {
        return {width: '125px', height: '51px'}
      }
    }else {
      if(props.real_user_insurance){
        return {width: '100px', height: '51px'}
      }else {
        return {width: '100px', height: '41px'}
      }
    }
  }


  return (
    <div
      className={
        isHome()
          ? "c-logo c-logo--big"
          : "c-logo c-logo--small"
      }
    >
      <Link to="/home">
        <div>
          <Icon
            width={getDimension().width}
            height={getDimension().height}
            className={"logo"}
            name={props.real_user_insurance ? "LogoInsurance" : "Logo"}
            // name={props.real_user_insurance ? "LogoInsurance" : "LogoPro"}
          />
        </div>
      </Link>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    real_user_insurance: state.session.real_user_insurance,
  };
}

export default connect(mapStateToProps, null)(Logo);
