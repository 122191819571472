import { useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import StackHeader from "../../components/stackHeader";
import Icon from "../../components/icons";
import PieHighcharts from "../../components/PieHighcharts";
import HeroCard from "./heroCard";
import Loading from "../../components/loading";
import DashboardTable from "./dashboardTable";

import { getDashboard } from "../../actions/dashboard";

// Mocks
// import { introCards } from "./mocks/introCards";
// import { pieHighChartData } from "./mocks/pieHighChartData";
// import { tableData } from "./mocks/tableData";
import { ownershipData } from "./mocks/ownershipData";
// import { networthEvolutionData } from "./mocks/networthEvolutionData";
import { permitsData } from "./mocks/permitsData";
import { custodianData } from "./mocks/custodianData";

const Dashboard = (props) => {
  const { language: lang } = props.match.internalization;

  const [tooltip, setTooltip] = useState(null);
  const [loading, setLoading] = useState(true);
  const [lastMarketClose, setLastMarketClose] = useState({});
  const [introCards, setIntroCards] = useState([]);
  const [pieHighChartData, setPieHighChartData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const tooltipRef = useRef(null);

  const fetchDashboard = useCallback(() => {
    props
      .getDashboard(props.authentication_token)
      .then((data) => successDashboard(data))
      .catch((err) => errorDashboard(err))
      .finally(() => finallyDashboard());
  }, [props.getDashboard, props.authentication_token]);

  useEffect(() => {
    fetchDashboard();
  }, [fetchDashboard]);

  const successDashboard = (data) => {
    console.log("DATA", data);
    mappedData(data);
    props.getBreadcrumbs({
      assetUrl: "/dashboard",
      url: "/dashboard",
      assetTitle: "Dashboard",
    });
  };

  const errorDashboard = (error) => {
    console.log("Dashboard error: ", error);
  };

  const finallyDashboard = () => {
    setLoading(false);
  };

  const mappedData = (data) => {
    // Last market close
    setLastMarketClose(data.market_closed);

    // Intro cards
    setIntroCards([
      { clients: data.clients },
      { actity_last_30_days: data.actity_last_30_days },
      { total_aum: data.total_aum },
      { total_financial_aum: data.total_financial_aum },
      { average_size: data.average_size },
      { fa_percentage: data.fa_percentage },
      { other_percentage: data.other_percentage },
    ]);

    // Pie High Chart
    setPieHighChartData(data.pie_high_charts.data);

    // Table
    setTableData(data.clients_table);
  };

  const pieSelected = (pie, nested) => {
    console.log(pie, nested);
  };

  const renderTooltip = (item) => {
    const languageUserAgent = /^es/.test(lang) ? "es" : "en";

    return (
      <div
        ref={tooltipRef}
        style={{
          transition: "all 300ms ease-in-out",
          opacity: 0,
          pointerEvents: "none",
          width: "fit-content",
        }}
        className="container-tooltip container-tooltip--no-triangle"
      >
        {tooltip && (
          <p
            style={{ textAlign: "center" }}
            dangerouslySetInnerHTML={{ __html: tooltip.text }}
          ></p>
        )}
      </div>
    );
  };

  return (
    <>
      {loading && <Loading />}
      <div className="main-wrapper market">
        <StackHeader
          title="Dashboard"
          icon={
            <Icon name="News" colorPrimary={"#005774"} colorSecondary="#f3b01c" iconBig={true} />
          }
          help={false}
          helpAction={() => alert("boton de info del stackHeader")}
        />

        <div class="table-preheader" style={{ margin: 0 }}>
          <p>
            <strong>
              <span>{lastMarketClose.title}:</span> {lastMarketClose.amount}
            </strong>
          </p>
        </div>

        <div className="wrapper-cards wrapper-cards--dashboard">
          <div style={{ flex: 1 }}>
            <div className="container-cards grid grid--col-3">
              {introCards.map((item, index) => {
                return (
                  <HeroCard
                    item={item}
                    lang={lang}
                    setTooltip={(item, ref) => {
                      if (!tooltip) {
                        setTooltip(item.total_aum.tooltip);
                      } else {
                        if (tooltip.title && tooltip.title !== item.title) {
                          setTooltip(item.total_aum.tooltip);
                        } else {
                          setTooltip(null);
                        }
                      }

                      setTimeout(() => {
                        tooltipRef.current.style.opacity = 1;
                        tooltipRef.current.style.top = ref.current.offsetTop + "px";
                        tooltipRef.current.style.left = ref.current.offsetLeft + "px";
                      }, 500);
                    }}
                  />
                );
              })}
            </div>

            {tooltip && renderTooltip(tooltip)}
          </div>

          <div style={{ height: 192, maxWidth: 600, margin: "0 auto", overflowY: "hidden" }}>
            <PieHighcharts
              pieSelected={(pie) => pieSelected(pie)}
              data={pieHighChartData}
              size={112}
            />
          </div>
        </div>

        {tableData.length > 0 && (
          <DashboardTable
            data={tableData}
            history={props.history}
            lang={lang}
          />
        )}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    authentication_token: state.session.authentication_token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDashboard: (authentication_token, success, error) =>
      dispatch(getDashboard(authentication_token, success, error)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
