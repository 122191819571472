import React, {useRef, useEffect} from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const PieHighcharts = ({ title, titleResult, data, pieSelected, centerLabel, style = {}, size = 280, height, width}) => {

  const pieChart = useRef(null);
  return (
    <div className="c-charts" style={style}>
      {title && (
        <p className={`c-charts__title ${centerLabel && 'c-charts__title__centered'}`}>
          <span className="c-charts__title__highlighted">{titleResult}</span>
          {title}
        </p>
      )}

      <HighchartsReact
        ref={pieChart}
        containerProps={{ className: "c-charts__pie" }}
        highcharts={Highcharts}
        options={{
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: "pie",
            height: height || 500,
          },
          title: {
            text: "",
          },
          tooltip: {
            style: {
              display: "none",
            }
          },
          // tooltip: {
          //   pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
          //   useHTML: true,
          //   style: {
          //     padding: 0,
          //   },
          // },
          accessibility: {
            point: {
              valueSuffix: "%",
            },
          },
          plotOptions: {
            pie: {
              size: size,
              allowPointSelect: true,
              cursor: "pointer",
              dataLabels: {
                enabled: true,
                // format: "<b>{point.name}</b>: <span class='highcharts-label-percentage'>{point.percentage:.2f}%</span>",
                formatter: function () {
                  let count = ""
                  if(this.series.userOptions.data[this.colorIndex] && this.series.userOptions.data[this.colorIndex].value && this.series.userOptions.data[this.colorIndex].enable_counter){
                    count = "   ("+this.series.userOptions.data[this.colorIndex].value+")"
                  }
                  var s = "<b>"+this.key+"</b>: <span class='highcharts-label-percentage'>"+this.percentage.toFixed(2)+"%"+count+"</span>" 
                  if(this.percentage == 100){
                    s = "<b>"+this.key+"</b>: <span class='highcharts-label-percentage'>"+this.percentage.toFixed(0)+"%</span>" 
                  }

                  return s;
                }
              },
            },
            series: {
              cursor: 'pointer',
              point: {
                events: {
                  click: function () {
                    // console.log('this', pieChart.plotOptions)
                    pieSelected && pieSelected(this)
              //call function passing this values as arguments
                  },
                }
              }
            }
          },
          colors: [
            "#005774",
            "#808285",
            "#EA9323",
            "#F2bC76",
            "#A7A9AC",
            "#6096A8",
            "#9FC0CB",
            "#DFEAEE",
            "#F4C991",
            "#D1D3D4",
          ],
          series: [
            {
              name: "Brands",
              colorByPoint: true,
              innerSize: '70%',
              data: data,
            },
          ],
        }}
      />
    </div>
  );
};

export default PieHighcharts;
