import { useRef, useState, useEffect, memo, useCallback } from "react";
import Icon from "../../components/icons";
import { connect } from "react-redux";

import { canManage, canRead } from "../../helpers/permits";
import BarsGraphic from "../../components/barsGraphic";
import LineHighChart from "../../components/LineHighChart";
import NestedListOwnership from "../../components/nestedListOwnership";
import Arrow from "../../components/icons/arrow";
import PieHighcharts from "../../components/PieHighcharts";
import Loading from "../../components/loading";
import LinkBlock from "../../components/linkBlock";

import TableRecentOrders from "../../components/TableRecentOrders";

import { getFilteredDataBarsGraph } from "../../actions/dashboard";
import { getRecentOrdersActivities } from "../../actions/balance";

const DashboardTable = memo((props) => {
  const { data: tableData } = props;


  const [filteredDataBarsGraph, setFilteredDataBarsGraph] = useState({});
  const [financialAssetEvolution, setFinancialAssetEvolution] = useState("consolidated");
  const [filtersInternalization, setFiltersInternalization] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [netWorthEvolutionLoading, setNetWorthEvolutionLoading] = useState(false);

  const [selectedRowIndex, setSelectedRowIndex] = useState(-1); // Track which row's graph is selected
  const [userId, setUserId] = useState(-1); // Track which row's graph is selected


  const firstEntry = useRef(0);
  const rowRef = useRef([]);
  const rowContentRef = useRef([]);
  const rowChevronRef = useRef([]);
/*
  useEffect(() => {
    // Initialize refs for row content
    rowContentRefs.current = Array(tableData.length).fill().map((_, i) => rowContentRefs.current[i] || null);
  }, [tableData.length]); */


  useEffect(() => {
    console.log("tableData", tableData);
  }, []);

  useEffect(() => {
    props.getRecentOrdersActivities(
      props.authentication_token,
      "?page=1",
      (responseJSON) => successRecentOrderesActivity(responseJSON),
      () => tableRecentOrdersError(),
    );
  }, []);

  const successRecentOrderesActivity = (responseJSON) => {
    setTotalPages(responseJSON.total_pages);
    setCurrentPage(responseJSON.page);
    setFiltersInternalization(responseJSON.internalization);
  };

  const tableRecentOrdersError = () => {
    console.log("TableRecentOrders error");
  };

  const filteredCall = (params) => {
    goToPage(params);
  };

  const goToPage = (params) => {
    if (params !== "") {
      props.getRecentOrdersActivities(
        props.authentication_token,
        params,
        (responseJSON) => successRecentOrderesActivity(responseJSON),
        () => tableRecentOrdersError(),
      );
    }
  };

  /* const showContent = (index) => {

    if (rowContentRef.current[index].classList.contains("is-active")) {
      rowContentRef.current[index].classList.remove("is-active");
      rowChevronRef.current[index].classList.remove("is-active");
    } 
    else {
      rowContentRef.current.forEach((row, i) => {
        if(i !== index) {
          row.classList.remove("is-active");
          rowChevronRef.current[i].classList.remove("is-active");
        }else {
          rowContentRef.current[index].classList.add("is-active");
          rowChevronRef.current[index].classList.add("is-active");
        }
      });
    }
  }; */

  const showContent = (index) => {
    if (rowContentRef.current[index].classList.contains("is-active")) {
      rowContentRef.current[index].classList.remove("is-active");
      rowChevronRef.current[index].classList.remove("is-active");
    } else {
      rowContentRef.current[index].classList.add("is-active");
      rowChevronRef.current[index].classList.add("is-active");
    }
  };

  const ownershipAction = (id, url) => {
    if (url.indexOf("https://") != -1 || url.indexOf("http://") != -1) {
      window.location = url;
    }
  };

  const updateNetWorthData = (userId, index) => {
    filteredBarsFinancialAction(`balance_principal/net_worths?frecuency=month&times=10&user_id=${userId}&bulltick_pro=true`, index);
  }

  const filteredBarsFinancialAction = (url, index) => {
    setNetWorthEvolutionLoading(true);
    props.getFilteredDataBarsGraph(
      props.authentication_token,
      url,
      (responseJson) => successFilteredBars(responseJson, index),
      () => errorFilteredBars(),
    );
  };

  const successFilteredBars = (data, index) => {

    console.log("PROPS FILTERED DATA BARS => ", data.balance_index.bars_graphic_data);

    setSelectedRowIndex(index);
    setFilteredDataBarsGraph(data.balance_index.bars_graphic_data);
    setNetWorthEvolutionLoading(false);
  };

  const errorFilteredBars = () => {
    console.log("filtered bars graph error");
  };

  const pieSelected = (pie, nested) => {
    console.log(pie, nested);
  };

  return (
    <div className="c-table-dashboard__wrapper" key={"table-dashboard"}>
      <div className="c-table-dashboard">
        <div className="c-table-dashboard__header">
          <div className="c-table-dashboard__row">
            {Object.keys(tableData[0]).map((item, index) => {
              const title = item.includes("_") ? item.split("_").join(" ") : item;
              if (item !== "content") {
                return (
                  <div className="c-table-dashboard__cell" key={`${index}-head-item`}>
                    <span>{title.includes("favorite") ? "Client" : title}</span>
                  </div>
                );
              }
            })}
          </div>
        </div>

        <div className="c-table-dashboard__body">
          {tableData.map((item, indexRow) => {
            return (
              <>
                {/* Row */}
                <div
                  key={`${indexRow}-row`}
                  className="c-table-dashboard__row"
                  ref={(el) => (rowRef.current[indexRow] = el)}
                  onClick={() => {
                    showContent(indexRow);
                    setSelectedRowIndex(indexRow)
                    updateNetWorthData(item.id, indexRow)
                  }}
                >
                  {Object.entries(item).map(([key, value], index) => {
                    /* console.log("key", key)
                    console.log("value", value) */

                    // Check if it's content to avoid painting it like a table cell
                    if (key !== "content") {
                      if (key === "is_favorite") {
                        return (
                          <div className="c-table-dashboard__cell" key={`${index}-body-item`}>
                            <div
                              title="Favorite client"
                              className="c-table-dashboard__cell-chevron"
                              ref={(el) => (rowChevronRef.current[indexRow] = el)}
                            >
                              <Icon name="SingleArrow" orientation={"down"} />
                            </div>
                            <span title="Favorite client">{indexRow + 1}</span>
                            <span title="Favorite client" className="c-table-dashboard__cell-star">
                              <Icon name="Star" color="#f3b01c" />
                            </span>
                          </div>
                        );
                      } else if (value.url) {
                        return (
                          <div className="c-table-dashboard__cell" key={`${index}-body-item`}>
                            <a href={value.url}>
                              <Icon name="Eye" />
                            </a>
                          </div>
                        );
                      } else if (value.pdf) {
                        return (
                          <div className="c-table-dashboard__cell" key={`${index}-body-item`}>
                            <a href={value.pdf} target="_blank" rel="noreferrer">
                              <Icon name="DocOk" />
                            </a>
                          </div>
                        );
                      } else if (key === "notes") {
                        return (
                          <div className="c-table-dashboard__cell" key={`${index}-body-item`}></div>
                        );
                      } else if (value === true) {
                        return (
                          <div className="c-table-dashboard__cell" key={`${index}-body-item`}>
                            <a href={value.url} target="_blank" rel="noreferrer">
                              <Icon name="Check" />
                            </a>
                          </div>
                        );
                      } else if (value === false) {
                        return (
                          <div className="c-table-dashboard__cell" key={`${index}-body-item`}>
                            <a href={value.url} target="_blank" rel="noreferrer">
                              <Icon name="Close" color="#f3b01c" />
                            </a>
                          </div>
                        );
                      } else if (value.status) {
                        return (
                          <div className="c-table-dashboard__cell" key={`${index}-body-item`}>
                            <span style={{ flex: 1 }}>{value.value}</span>
                            {value.value && (
                              <Arrow
                                style={{
                                  transform:
                                    value.status === "up" ? "rotate(180deg)" : "rotate(0deg)",
                                  marginLeft: 8,
                                }}
                                color={value.status === "up" ? "#80abba" : "#f3b01c"}
                              />
                            )}
                          </div>
                        );
                      } else {
                        return (
                          <div className="c-table-dashboard__cell" key={`${index}-body-item`}>
                            <span>{value}</span>
                          </div>
                        );
                      }
                    }
                  })}
                </div>
                {/* @Row */}

                {/* Row content */}
                <div
                  key={`${indexRow}-row-content`}
                  className="c-table-dashboard__row-content-wrapper"
                  ref={(el) => (rowContentRef.current[indexRow] = el)}
                >
                  <div className="c-table-dashboard__row-content">
                    {Object.entries(item).map(([key, value], index) => {
                      /* First row of content */
                      if (key === "content") {
                        return (
                          <>
                            <div className="c-table-dashboard__row-content-charts">
                              <div style={{ width: "48%" }}>
                                <h2 className="headingH2">
                                  {value.net_worth_data.bars_graphic_data.title}
                                </h2>

                                {(Object.keys(value.net_worth_data.bars_graphic_data).length === 0 || netWorthEvolutionLoading) && (
                                    <Loading simple={true} text={props.internalization.loading} />
                                  )}


                                {Object.keys(value.net_worth_data.bars_graphic_data).length > 0 && (
                                  <BarsGraphic
                                    data={ (selectedRowIndex === indexRow && Object.keys(filteredDataBarsGraph).length > 0) ? filteredDataBarsGraph : value.net_worth_data.bars_graphic_data }
                                    filterAction={(url) => filteredBarsFinancialAction(url, indexRow)}
                                    xTickValues={(selectedRowIndex === indexRow && Object.keys(filteredDataBarsGraph).length > 0) 
                                      ? filteredDataBarsGraph.type.pasive.map((elem) => elem.x) 
                                      : value.net_worth_data.bars_graphic_data.type.pasive.map((elem) => elem.x)
                                    }
                                    yTickValues={(selectedRowIndex === indexRow && Object.keys(filteredDataBarsGraph).length > 0)
                                      ? filteredDataBarsGraph.type.pasive.map((elem) => elem.y)
                                      : value.net_worth_data.bars_graphic_data.type.pasive.map((elem) => elem.y)
                                    }
                                  />
                                )}
                              </div>

                              {value.consolidated_data.consolidated_graph &&
                                value.custodian_data.custodian.graph_all &&
                                Object.keys(value.custodian_data.custodian.graph_custodian).length >
                                  0 && (
                                  <div style={{ width: "48%" }}>
                                    <h2 className="headingH2">Financial Assets Evolution</h2>
                                    <div
                                      className="filter-buttons-wrapper"
                                      style={{ marginTop: 36 }}
                                    >
                                      {value.consolidated_data.consolidated_graph && (
                                        <button
                                          className={`filter-buttons btn ${financialAssetEvolution === "consolidated" ? "primary" : "primary border"}`}
                                          type="button"
                                          onClick={() => setFinancialAssetEvolution("consolidated")}
                                        >
                                          <span className="btn-text">Consolidated</span>
                                        </button>
                                      )}

                                      {value.custodian_data.custodian.graph_all &&
                                        Object.keys(value.custodian_data.custodian.graph_custodian)
                                          .length > 0 && (
                                          <button
                                            className={`filter-buttons btn ${financialAssetEvolution === "custodian" ? "primary" : "primary border"}`}
                                            type="button"
                                            onClick={() => setFinancialAssetEvolution("custodian")}
                                          >
                                            <span className="btn-text">Custodian</span>
                                          </button>
                                        )}
                                    </div>

                                    {financialAssetEvolution === "consolidated" &&
                                      value.consolidated_data.consolidated_graph && (
                                        <LineHighChart
                                          styles={{ paddingTop: 0 }}
                                          key={"graph_t"}
                                          type="account"
                                          updateData={(itemsSelected, data_temp) =>
                                            console.log(itemsSelected, data_temp)
                                          }
                                          currentData={
                                            value.consolidated_data.consolidated_graph.last_month
                                          }
                                          dates={value.consolidated_data.consolidated_graph.dates}
                                          allCustodians={{}}
                                          accountData={value.consolidated_data.consolidated_graph}
                                          currentCustodianName={
                                            props.user.language.includes("es")
                                              ? "Consolidado"
                                              : "Consolidated"
                                          }
                                          centerLabel={true}
                                          languageUserAgent={props.user.language}
                                          yAxisTitle={
                                            props.user.language.includes("es")
                                              ? "Rendimiento"
                                              : "Performance"
                                          }
                                          setFilterType={(type) => console.log(type)}
                                          extra_title={{}}
                                        />
                                      )}

                                    {financialAssetEvolution === "custodian" &&
                                      value.custodian_data.custodian.graph_all &&
                                      Object.keys(value.custodian_data.custodian.graph_custodian)
                                        .length > 0 && (
                                        <LineHighChart
                                          styles={{ paddingTop: 0 }}
                                          key={"graph_t_custodian"}
                                          type="custodian"
                                          allSelected={
                                            value.custodian_data.custodian.custodian_all_name
                                          }
                                          preSelected={false}
                                          updateData={(itemsSelected, data_temp) => ""}
                                          currentData={
                                            value.custodian_data.custodian.graph_custodian.dates
                                              .last_month
                                          }
                                          dates={
                                            value.custodian_data.custodian.graph_custodian.dates
                                          }
                                          allCustodians={value.custodian_data.custodian.graph_all}
                                          currentCustodianName={""}
                                          centerLabel={true}
                                          languageUserAgent={props.user.language}
                                          yAxisTitle={
                                            props.user.language.includes("es")
                                              ? "Rendimiento"
                                              : "Performance"
                                          }
                                          extra_title={value.custodian_data.custodian.extra_title}
                                        />
                                      )}
                                  </div>
                                )}
                            </div>

                            <div className="c-table-dashboard__row-content-charts">
                              <div style={{ flex: 1 }}>
                                <h2 className="headingH2" style={{ marginBottom: 36 }}>
                                  {value.activity.title}
                                </h2>
                                {Object.keys(filtersInternalization).length > 0 && (
                                  <TableRecentOrders
                                    dataTable={value.activity.data.rows}
                                    filters_internalization={filtersInternalization}
                                    filteredCall={(params) => filteredCall(params)}
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                  />
                                )}
                              </div>
                            </div>

                            <div className="c-table-dashboard__row-content-charts">
                              <div style={{ maxHeight: 300, overflow: "auto", flex: 1 }}>
                                <h2
                                  className="headingH2"
                                  style={{
                                    marginBottom: 36,
                                    position: "sticky",
                                    top: 0,
                                    background: "white",
                                    zIndex: 9999,
                                  }}
                                >
                                  Ownership Structure
                                </h2>
                                {value.ownership_data.ownership_structure.data[0].descendants
                                  .length > 0 ? (
                                  <NestedListOwnership
                                    mainClass={"nestedlist--padding"}
                                    color={"nestedlist__item--grey"}
                                    data={value.ownership_data.ownership_structure.data}
                                    ownership={true}
                                    action={(id, url) => ownershipAction(id, url)}
                                    levelColors={true}
                                    square={"nestedlist__item--square"}
                                  />
                                ) : (
                                  <p>{value.ownership_data.ownership_structure.no_data_title}</p>
                                )}
                              </div>
                              <div style={{ flex: 1 }}>
                                <div
                                  style={{
                                    height: 192,
                                    maxWidth: 600,
                                    margin: "0 auto",
                                    overflowY: "hidden",
                                  }}
                                >
                                  <div style={{ transform: "translateY(-37%)" }}>
                                    <PieHighcharts
                                      pieSelected={(pie) => pieSelected(pie)}
                                      data={value.pie_high_chart.pie_graphic_assets.data}
                                      size={112}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Info */}
                            <div className="c-table-dashboard__row-content-info">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  paddingTop: 24,
                                  paddingBottom: 24,
                                }}
                              >
                                <div class="table-preheader">
                                  {value.last_access && (
                                    <p>
                                      <strong>
                                        <span>{value.last_access.title}</span>{" "}
                                        {value.last_access.value}{" "}
                                      </strong>
                                    </p>
                                  )}
                                  {value.invitations && (
                                    <p>
                                      <strong>
                                        <span>{value.invitations.title}</span>{" "}
                                        {value.invitations.value}{" "}
                                      </strong>
                                    </p>
                                  )}
                                </div>
                                <div
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(3, minmax(250px, 1fr))",
                                  }}
                                >
                                  {value.loaded_items.map((item) => {
                                    return (
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          height: 24,
                                        }}
                                      >
                                        <strong>{Object.keys(item)[0]}:</strong>
                                        {Object.values(item)[0] === true ? (
                                          <Icon name="Check" width={14} />
                                        ) : (
                                          <Icon name="Close" width={14} color="#f3b01c" />
                                        )}
                                      </p>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      }
                    })}
                  </div>
                </div>
                {/* @Row content */}
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
});

function mapStateToProps(state) {
  return {
    authentication_token: state.session.authentication_token,
    user: state.session.user,
    internalization: state.session.internalization,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFilteredDataBarsGraph: (authentication_token, url, success, error) =>
      dispatch(getFilteredDataBarsGraph(authentication_token, url, success, error)),
    getRecentOrdersActivities: (authentication_token, page, url, success, error) =>
      dispatch(getRecentOrdersActivities(authentication_token, page, url, success, error)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(DashboardTable);
