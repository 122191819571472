// Dependencies
import React from "react";

const Arrow = ({ width = 24, height = 24, color='red', style={} }) => {
  return (
    <svg width={width} height={height} style={style} viewBox="0 0 24 24">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.8889 2C12.5026 2 13 2.49747 13 3.11111V18.2065L18.881 12.3255C19.3149 11.8916 20.0185 11.8916 20.4524 12.3255C20.8862 12.7593 20.8862 13.4629 20.4524 13.8968L12.6746 21.6746C12.2407 22.1085 11.5371 22.1085 11.1032 21.6746L3.32543 13.8968C2.89152 13.4629 2.89152 12.7593 3.32543 12.3255C3.75936 11.8916 4.46287 11.8916 4.89679 12.3255L10.7778 18.2065V3.11111C10.7778 2.49747 11.2752 2 11.8889 2Z"
        fill={color}
      />
    </svg>
  );
};

export default Arrow;
