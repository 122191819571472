// Dependencies
import React from "react";
import { Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import Analytics from "react-router-ga";
import { GOOGLE_ANALYTICS_ID } from "./constants";

// Store
import configureStore from "./configureStore";

// Components
import Header from "./components/header";
import Logo from "./components/logo";
import Breadcrumbs from "./components/breadcrumbs";
import Footer from "./components/footer";
import Home from "./screens/home/home";
import Dashboard from "./screens/dashboad/dashboard";
import BalanceIndexScreen from "./screens/balance/balanceIndexScreen";
import BalanceShowScreen from "./screens/balance/balanceShowScreen";
import BalanceDetailScreen from "./screens/balance/balanceDetailScreen";
import BalanceEditScreen from "./screens/balance/balanceEditScreen";
import BalanceNewScreen from "./screens/balance/balanceNewScreen";
import OwnershipIndex from "./screens/ownership/index";
import OwnershipEdit from "./screens/ownership/edit";
import WealthMap from "./screens/ownership/wealth_map";
import WealthMapMobile from "./screens/ownership/wealth_map_mobile";
// import OwnershipCreate from './screens/ownership/create'
import OwnershipShow from "./screens/ownership/show";
import PrivateRoute from "./helpers/privateRoute";
import SignIn from "./screens/signin/signIn";
import DocumentsIndexScreen from "./screens/documents/documentsIndexScreen";
import DocumentsShowScreen from "./screens/documents/documentsShowScreen";
import DocumentsDetailScreen from "./screens/documents/documentsDetailScreen";
import NotificationsIndexScreen from "./screens/notifications/notificationsIndexScreen";
import BalanceValuationsScreen from "./screens/balance/valuations";
import ProfileEditScreen from "./screens/profile/profileIndexScreen";
import ProfileChangeScreen from "./screens/profile/profileChangeScreen";
import SearchIndexScreen from "./screens/search/searchIndexScreen";
import AlertsIndexScreen from "./screens/alerts/alertsIndexScreen";

import Recovery from "./screens/signin/recovery";

import ServicesIndexScreen from "./screens/services/servicesIndexScreen";
import ServicesShowScreen from "./screens/services/servicesShowScreen";
import ServicesDetailScreen from "./screens/services/servicesDetailScreen";
import SideBar from "./screens/menu/sideBar";
import InProgress from "./components/inProgress";
import MarketIndexScreen from "./screens/market/marketIndexScreen";

import Video from "./screens/video";
import FinancialStatements from "./screens/financialStatements";

// Helpers
import { createBrowserHistory } from "history";

const customHistory = createBrowserHistory();
const store = configureStore();

function App() {
  const _closeSideBar = (e) => {
    if (
      document.body.classList.contains("open-sidebar") &&
      e.currentTarget.className === "wrapper-main" &&
      !(
        e.target.closest(".burger-menu") !== null &&
        e.target.closest(".burger-menu").className === "burger-menu"
      )
    ) {
      document.body.classList.remove("open-sidebar");
    }
  };

  return (
    <Provider history={customHistory} store={store}>
      <Analytics id={GOOGLE_ANALYTICS_ID} debug>
        <main className="wrapper-main" onClick={(e) => _closeSideBar(e)}>
          <SideBar history={customHistory} />
          <Switch>
            <Route exact path="/" component={SignIn} />
            <Route exact path="/recovery" component={Recovery} />

            <PrivateRoute
              exact
              path="/home"
              component={Home}
              header={Header}
              footer={Footer}
            />
            <PrivateRoute
              exact
              path="/dashboard"
              component={Dashboard}
              breadcrumbs={Breadcrumbs}              
              header={Header}
              footer={Footer}
            />
            <PrivateRoute
              exact
              path="/financial-statements"
              component={FinancialStatements}
              breadcrumbs={Breadcrumbs}              
              header={Header}
              footer={Footer}
            />
            <PrivateRoute
              path="/balance-index"
              component={BalanceIndexScreen}
              breadcrumbs={Breadcrumbs}
              header={Header}
              footer={Footer}
            />
            <PrivateRoute
              path="/balance-show"
              component={BalanceShowScreen}
              header={Header}
              breadcrumbs={Breadcrumbs}
              footer={Footer}
            />
            <PrivateRoute
              path="/balance-detail"
              component={BalanceDetailScreen}
              header={Header}
              breadcrumbs={Breadcrumbs}
              footer={Footer}
            />
            <PrivateRoute
              path="/balance-new"
              component={BalanceNewScreen}
              header={Header}
              breadcrumbs={Breadcrumbs}
              footer={Footer}
            />
            <PrivateRoute
              path="/balance-edit"
              component={BalanceEditScreen}
              header={Header}
              breadcrumbs={Breadcrumbs}
              footer={Footer}
            />
            <PrivateRoute
              path="/documents-index"
              component={DocumentsIndexScreen}
              header={Header}
              footer={Footer}
            />
            <PrivateRoute
              path="/documents-show"
              component={DocumentsShowScreen}
              header={Header}
              breadcrumbs={Breadcrumbs}
              footer={Footer}
            />
            <PrivateRoute
              path="/document-detail"
              component={DocumentsDetailScreen}
              header={Header}
              footer={Footer}
            />
            <PrivateRoute
              path="/notifications"
              component={NotificationsIndexScreen}
              header={Header}
              breadcrumbs={Breadcrumbs}
              footer={Footer}
            />
            <PrivateRoute
              path="/valuations"
              component={BalanceValuationsScreen}
              header={Header}
              breadcrumbs={Breadcrumbs}
              footer={Footer}
            />
            />
            <PrivateRoute
              path="/ownership-index"
              component={OwnershipIndex}
              header={Header}
              breadcrumbs={Breadcrumbs}
              footer={Footer}
            />
            <PrivateRoute
              path="/wealth-map"
              component={WealthMap}
              header={Header}
              breadcrumbs={Breadcrumbs}
              footer={Footer}
            />
            <Route
              path="//wealth-map-mobile"
              component={WealthMapMobile}
            />
            <Route
              path="/wealth-map-mobile"
              component={WealthMapMobile}
            />
            <PrivateRoute
              path="/ownership-edit"
              component={OwnershipEdit}
              header={Header}
              footer={Footer}
            />
            <PrivateRoute
              path="/ownership_structure/:type/:id"
              component={OwnershipShow}
              header={Header}
              breadcrumbs={Breadcrumbs}
              footer={Footer}
            />
            <PrivateRoute
              path="/profile"
              component={ProfileEditScreen}
              header={Header}
              breadcrumbs={Breadcrumbs}
              footer={Footer}
            />
            <PrivateRoute
              path="/profile_edit"
              component={ProfileChangeScreen}
              header={Header}
              breadcrumbs={Breadcrumbs}
              footer={Footer}
            />
            <PrivateRoute
              path="/services/:id/files"
              component={ServicesDetailScreen}
              header={Header}
              //breadcrumbs={Breadcrumbs}
              footer={Footer}
            />
            <PrivateRoute
              path="/services/:id"
              component={ServicesShowScreen}
              header={Header}
              breadcrumbs={Breadcrumbs}
              footer={Footer}
            />
            <PrivateRoute
              path="/researchs/:id"
              component={ServicesShowScreen}
              header={Header}
              breadcrumbs={Breadcrumbs}
              footer={Footer}
            />
            <PrivateRoute
              path="/search"
              component={SearchIndexScreen}
              header={Header}
              breadcrumbs={Breadcrumbs}
              footer={Footer}
            />
            <PrivateRoute
              path="/market"
              component={MarketIndexScreen}
              header={Header}
              breadcrumbs={Breadcrumbs}
              footer={Footer}
            />
            <PrivateRoute
              path="/servicesProduct"
              component={InProgress}
              header={Header}
              breadcrumbs={Breadcrumbs}
              footer={Footer}
            />
            <PrivateRoute
              path="/alerts"
              component={AlertsIndexScreen}
              header={Header}
              breadcrumbs={Breadcrumbs}
              footer={Footer}
            />
            <PrivateRoute
              path="/inProgress"
              component={InProgress}
              header={Header}
              breadcrumbs={Breadcrumbs}
              footer={Footer}
            />
          </Switch>
        </main>
        <Route exact path="/video" component={Video} />
      </Analytics>
    </Provider>
  );
}

export default App;
