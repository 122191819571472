const PermissionCircle = ({ item }) => {
  if (!item.hasOwnProperty("see_actives_permission")) return null;
  return (
    <div
      style={{
        position: "absolute",
        top: 4,
        right: 4,
        width: 16,
        height: 16,
        borderRadius: 8,
        cursor: "pointer",
        border: "1px solid #f3b01c",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: 15,
          height: 14,
          borderRadius: 8,
          backgroundColor: "#f3b01c",
          cursor: "pointer",
          clipPath: item.see_actives_permission === 'full' 
            ? "inset(0 0 0 0)" 
            : item.see_actives_permission === 'medium' 
              ? "inset(0 0 0 50%)" 
              : "inset(0 0 0 100%)",
        }}
      ></div>

    </div>
  );
};

export default PermissionCircle;
