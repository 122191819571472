// Dependencies
import React from "react";
import Slider from "react-slick";
import classNames from "classnames";
import {internalization} from '../../../constants'

//helpers
import { getIconAsset } from "../../../helpers/renderIconAssets";
import { dataTableFicha } from '../../../helpers/dataTablesTemp'

// Components
import Tabs from "../../../components/Tabs";
import LineHighChart from "../../../components/LineHighChart";
import TableAlternatives from "../../../components/TableAlternatives";
import TableBlock from "../../../components/tableBlock";

import DetailContentBlock from "../../../components/detailContentBlock";
import Icon from "../../../components/icons";
import Modal from "../../../components/modal";
import Input from "../../../components/input";
import SelectMultiple from "../../../components/selectMultiple";
import SelectMultipleCustodian from "../../../components/selectMultipleCustodian";

import RenderDocIcon from "../../../helpers/renderDocIcon";
import { avatars } from "../../../assets/resources";
import AlertModal from "../../../components/alertModal";
import NestedListOwnership from "../../../components/nestedListOwnership";
import Table from '../../../components/Table'
import Pagination from '../../../components/pagination'
import TableDropdown from "../../../components/TableDropdown";
import TableMultipleDropdown from "../../../components/TableMultipleDropdown";


import LogoDomum from "../../../components/icons/logo_Domum";

let imagesToZoom;

const MAX_SHOW = 12;


class DetailTypeOne extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      current: 0,
      imagesToZoom: [],
      showImageModal: false,
      showAlert: false,
      showAlertAsk: false,
      linkedResource: {},
      btnShowAllItems: {},
      contentStep: 'main',
      pieSelectedElement: -1,
      screen_step_index: this.props.screen_step_index || 0,
      search_text: {},
      carouselImageSelected: 0,
      graph_key: "graph_custodians",
      setFilterType: "last_month",
      fromSearch: "",
      showSecurityModal: {},
      resource_key: ""
    };
  }

  //Carousel arrows//
  _next() {
    this.slider1.slickNext();
    this.slider2.slickNext();
    setTimeout(() => {
      let currentSlide = document.querySelector('.slick-current')
      this.setState({carouselImageSelected: currentSlide.getAttribute('data-index')})
    }, 1000)
  }

  _previous() {
    this.slider1.slickPrev();
    this.slider2.slickPrev();
    setTimeout(() => {
      let currentSlide = document.querySelector('.slick-current')
      this.setState({carouselImageSelected: currentSlide.getAttribute('data-index')})
    }, 1000)
  }
  //@Carousel arrows//

  getInputs() {
    if (this.props !== undefined && Object.keys(this.props.data).length !== 0) {
      if (this.props.data.form.hasOwnProperty("edit_asset")) {
        return this.props.data.form.edit_asset.inputs;
      } else {
        return this.props.data.form.edit.inputs.filter(
          (e) => !e.hidden && e.value !== ""
        );
      }
    } else {
      return [];
    }
  }

  componentDidMount() {
    let rk = ""
    if (this.props.data.form.hasOwnProperty("edit_asset")) {
      rk = this.props.data.form.edit_asset.resource_key;
    }
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
      btnShowAllItems: this._getbtnShowAllItems(),
      resource_key: rk
    });
    var that = this;
    document.body.onmousedown = function() { 
      that.setState({fromSearch: ""})
    }

  }

  _getImage(item) {
    if (
      item.avatar_url.avatar_default_id !== null &&
      item.avatar_url.avatar_default_id !== undefined
    ) {
      return avatars[item.avatar_url.avatar_default_id];
    } else if (item.avatar_url.url !== null) {
      return item.avatar_url.url;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let images = "";
    if (this.props !== undefined && Object.keys(this.props.data).length !== 0) {
      let images_found = this.getInputs().filter(
        (input) => input.id === "images"
      );
      images = images_found.length === 0 ? [] : images_found[0];

      let wrapperSlick = document.querySelector(".slick-slider");
      wrapperSlick !== null &&
        wrapperSlick.addEventListener("click", (e) => {
          try {
            let getCurrentSlide = e.path[2].getAttribute("data-index");
            this.props.setImagesToZoom(
              images.options.map((image) => ({ url: image.value, id: image.id })),
              getCurrentSlide
            );
          } catch (e) {}
        });
    }
    if(prevProps.successDeleteImage !== this.props.successDeleteImage){
      this.setState({showImageModal: false})
    }

    if(prevProps.screen_step_index !== this.props.screen_step_index && this.props.data && this.props.data.tabs){
      if(this.props.data.tabs[this.props.screen_step_index] === undefined){
        this.setState({contentStep: this.props.data.tabs[0].code, screen_step_index: 0})  
      }
      else{
        this.setState({contentStep: this.props.data.tabs[this.props.screen_step_index].code, screen_step_index: this.props.screen_step_index})  
      }
      
    }

    if(this.props.screen_step_index !== 0 && this.state.contentStep === "main" && this.props.data && this.props.data.tabs){
      if(this.props.data.tabs[this.props.screen_step_index] === undefined){
        if(this.state.screen_step_index !== 0 && this.state.contentStep === this.props.data.tabs[0].code){
          this.setState({contentStep: this.props.data.tabs[0].code, screen_step_index: 0})
        }
      }
      else{
        this.setState({contentStep: this.props.data.tabs[this.props.screen_step_index].code, screen_step_index: this.props.screen_step_index})
      }
      
    }

    if (this.props.location.search !== prevProps.location.search) {
       this.setState({contentStep: this.props.data.tabs[0].code, screen_step_index: 0})
    }
  }

  _topBottomClicked(name) {
    this.setState({fromSearch: name})
    document.getElementById('table-dropdown').scrollIntoView({
      behavior: 'smooth'
    });

  }
  _createImagesToZoom(item) {
    imagesToZoom = [
      {
        id: item.id,
        url: item.urls.large,
      },
    ];

    this.setState({
      imagesToZoom: imagesToZoom,
      showImageModal: true,
    });
  }

  _getOptions(all_items, state){
    let element = all_items[0]
    this.props.history.push("balance-detail?url=" + element.url.collection+"&id="+element.id, {
      url: "balance_principal/accounts/",
      id: element.id,
      from_sidebar: true
    });    
    let languageUserAgent = (/^es/).test(this.props.language) ? 'es' : 'en';   
    let title_found = this.getInputs().filter((input) => input.id === "name");
    let title =
        title_found.length === 0 ? this.props.data.title : title_found[0].value;

    this.props.setSpecialParent(
      [
      {title: internalization[languageUserAgent].models.home, code: "home", url: "/home"},
      {title: internalization[languageUserAgent].models.financial_assets, code: "financial_assets", url: "/balance-show?url=balance_principal/financial_assets"}, 
      {title: internalization[languageUserAgent].models.custodians, code: "custodians", url: "/balance-show?url=balance_principal/financial_assets&code=custodian"},
      {title: title, code: "custodian", url: "/balance-detail?url=balance_principal/custodians&id="+this.props.data.identifier.id}          
      ])

    this.props.fetch()
  }

  _parentSelected(input) {
    if (input.parent !== "") {
      let parent = this.getInputs().filter((e) => e.id === input.parent)[0];

      //parent.value
      if (parent) {
        let options_selected = parent.options.filter(
          (e) => e.id === parent.value
        );

        if (options_selected.length > 0) {
          let selected = options_selected[0].related_fields.filter(
            (e) => e === input.id
          );
          if (selected.length > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
      }
    } else {
      return true;
    }
  }

  _btnShowAllItems(type, show, length) {
    this.setState({
      btnShowAllItems: {
        ...this.state.btnShowAllItems,
        [type]: { show: show, lengthItems: show ? length : MAX_SHOW },
      },
    });
  }

  _calculateHeight() {
    let parent = document.querySelector(".two-columns");
    let wrapperForm;

    if (parent) {
      wrapperForm = parent && parent.querySelectorAll(".wrapper-form-item");
    }

    let totalHeight = 0;
    let textarea = false;

    if (wrapperForm && wrapperForm.length > 0) {
      for (let i = 0; i < wrapperForm.length; i++) {
        totalHeight = totalHeight + wrapperForm[i].offsetHeight + 8;
        if (wrapperForm[i].classList.contains("textarea")) {
          textarea = true;
        }
      }

      if (textarea) {
        totalHeight -= 48;
      } else {
        totalHeight -= 54;
      }
    }

    return totalHeight;
  }

  _typeRadio(elem) {
    if (elem.value === true) {
      return this.props.internalization.buttons.afirmative;
    } else {
      return this.props.internalization.buttons.negative;
    }
  }

  _ownershipAction(id, url) {
    if (url.indexOf("https://") != -1 || url.indexOf("http://") != -1) {
      window.location = url;
    }
  }

  _getbtnShowAllItems() {
    let obj = {};

    Object.keys(this.props.data).length > 0 &&
      Object.keys(this.props.data).map((item) => {
        obj = { ...obj, [item]: { show: false, lengthItems: MAX_SHOW } };
      });

    return obj;
  }

  _getCustodianAccounts(){
     if (this.props !== undefined && Object.keys(this.props.data).length !== 0) {
        if (this.props.data.form.hasOwnProperty("edit_asset")) {
          return this.props.data.form.edit_asset.inputs.filter((item) => item.id === "select_accounts")[0]
        } 
      }else{
        return null
      }    
  }

  _getCurrencyTypeByField(element, text_fiedls){
    // if(element.id == 'purchase_price'){
    //   let currency_type = text_fiedls.filter((item) => item.id == 'currency_type_purchase_price')[0]          
    //   return currency_type.value || "USD"
    // }
    // else{
      let currency_format = undefined
      let currency_type = text_fiedls.filter((item) => item.id == 'currency_type')
      let crypto_type = text_fiedls.filter((item) => item.id == 'crypto_type')
      if (currency_type.length > 0){
        let options = currency_type[0].options
        let selected_options = options.filter((elem) => elem.id === currency_type[0].value)
        if(selected_options.length > 0){
          currency_format = selected_options[0].currency_format
        }
        return currency_format || currency_type[0].value || "USD"  
      }
      else if (crypto_type.length > 0){
        return crypto_type[0].value || ""  
      }
      else{
        return "USD"  
      }
      
    // }
  } 

  _elementType(element, type){
    if(this.props.data.identifier.resourceable_type === "Other" && element.id === "value"){
      let crypto_type = this.getInputs().filter((input) => input.id === "crypto_type");  
      if(crypto_type.length > 0 && crypto_type[0].value !== ""){
        return "text"
      }
    }
    return type === "radio" ? "text" : type
  } 

  _elementValue(element, type){
    if(this.props.data.identifier.resourceable_type === "Other" && element.id === "value"){
      let crypto_type = this.getInputs().filter((input) => input.id === "crypto_type");  
      if(crypto_type.length > 0 && crypto_type[0].value !== ""){
        return crypto_type[0].value+" "+element.value
      }
    }
    return element.type === "radio" ? this._typeRadio(element) : element.value
  }

    handleTabsContent(tab, index){
      if(tab.code == "wealth_tab"){
        this.props.history.push("/wealth-map", {"is_ownership_linked": true, "id": this.props.data.identifier.resourceable_id, class: this.props.data.identifier.resourceable_type});
      }
      else{
        this.setState({contentStep: tab.code, screen_step_index: index, fromSearch: ""})
        this.props.handleTabChange && this.props.handleTabChange(index)  
      }
      
    }
  
  _onClickSecurity(id){
    let security_info = this.props.data.security_info
    if(security_info && security_info[id] !== undefined){
      this.setState({showSecurityModal: security_info[id]})  
    }
    
  }

    mainContent(maps,inputData, images, text_fiedls, languageUserAgent) {
      let builded_surface_type = text_fiedls.filter((e) => e.id == "builded_surface_type")[0]
      return(
        <div className="detailTypeOne-content">

          <div
            className={
              !this.props.data.ownership_structure &&
                !maps.value &&
                inputData.length > 3 &&
                (images.options == undefined || images.options.length == 0)
                  ? "one-column"
                  : "two-columns"
            }
          >

            {this.props.data.form !== undefined && this.getInputs().length > 0 && (
              <React.Fragment>
                <div
                  className={
                    !this.props.data.ownership_structure &&
                      !maps.value &&
                      inputData.length > 3 &&
                      (images.options == undefined || images.options.length == 0)
                        ? "one-column-left"
                        : ""
                  }
                >
                  {inputData.map((element, i) => {
                    let hasTooltip = element.tooltip ? element.tooltip.length > 0 : false;
                    if (i < inputData.length / 2) {
                      if (element.type !== "select") {
                        return (
                          <div
                            key={i+"_"+element.id+"_"+this.props.data.identifier.resourceable_type}
                            className={
                              element.type === "textarea"
                                ? "wrapper-form-item textarea"
                                : "wrapper-form-item"
                            }
                          >
                            <span className="input-label">{element.label}</span>
                            <Input
                              key={element.value}
                              id={element.id}
                              colorGrey={this.props.colorGrey}
                              name={element.name}
                              type={
                                this._elementType(element, element.type)
                              }
                              resource_key={this.state.resource_key}
                              disabled={true}
                              defaultValue={this._elementValue(element, element.type)}
                              value={this._elementValue(element, element.type)}
                              onChangeInput={(e) => ""}
                              onFocus={() => {}}
                              onBlur={() => {}}
                              showErrorLabel={false}
                              prepare_dates={{ [element.id]: element.value }}
                              errorLabel={"Este campo no puede estar vacío"}
                              currency_type={this._getCurrencyTypeByField(element, text_fiedls)}
                              hasTooltip={hasTooltip}
                              helperTooltip={[element.label, element.tooltip]}
                              sqft_type={text_fiedls.filter((e) => e.id == "sqft_type")[0]}
                              builded_surface_type={builded_surface_type}
                            />
                          </div>
                        );
                      } 
                      else if(element.id == "select_accounts"){
                        return (
                          <div key={i+"_"+element.id+"_"+this.props.data.identifier.resourceable_type} className="wrapper-form-item">
                            <SelectMultipleCustodian
                              colorGrey={this.props.colorGrey}
                              setRef={(ref) => (this.selectMultiple = ref)}
                              defaultValue={
                                element.id !== "select_accounts" ?
                                element.value !== "" &&
                                  element.options.filter(
                                    (e) => e.id === element.value
                                  )[0].value : element.value
                              }
                              label={element.label}
                              options={element.options}
                              multiple={element.id === "select_accounts"}
                              openOptions={() =>
                                  this.setState({
                                    modalOptions: !this.state.modalOptions,
                                  })
                              }
                              disabled={element.id !== "select_accounts"}
                              modalOptions={this.state.modalOptions}
                              getOptions={(items) =>
                                  this._getOptions(items, this.state)
                              }
                              // itemsSelected={
                              //   this.state.inputsValue.type !== undefined
                              //     ? this.state.inputsValue.type
                              //     : []
                              // }
                            />
                          </div>                          
                        )
                      } 
                      else {
                        return (
                          <div key={i+"_"+element.id+"_"+this.props.data.identifier.resourceable_type} className="wrapper-form-item">
                            <SelectMultiple
                              colorGrey={this.props.colorGrey}
                              setRef={(ref) => (this.selectMultiple = ref)}
                              defaultValue={
                                element.value !== "" &&
                                  element.options.filter(
                                    (e) => e.id === element.value
                                  )[0].value
                              }
                              label={element.label}
                              options={element.options}
                              multiple={element.id === "select_accounts"}
                              openOptions={() =>
                                  this.setState({
                                    modalOptions: !this.state.modalOptions,
                                  })
                              }
                              disabled={element.id !== "select_accounts"}
                              modalOptions={this.state.modalOptions}
                              getOptions={(items) =>
                                  this._getOptions(items, this.state)
                              }
                              // itemsSelected={
                              //   this.state.inputsValue.type !== undefined
                              //     ? this.state.inputsValue.type
                              //     : []
                              // }
                            />
                          </div>
                        );
                      }
                    }
                  })}
                </div>
                <div
                  className={
                    !this.props.data.ownership_structure &&
                      !maps.value &&
                      inputData.length > 3 &&
                      (images.options == undefined || images.options.length == 0)
                        ? "one-column-right"
                        : ""
                  }
                >

                  {inputData.map((element, i) => {
                    let hasTooltip = element.tooltip ? element.tooltip.length > 0 : false;
                    if (i >= inputData.length / 2) {
                      if (element.type !== "select") {
                        return (
                          <div key={i+"_"+element.id+"_"+this.props.data.identifier.resourceable_type}
                            className={
                              element.type === "textarea"
                                ? "wrapper-form-item textarea"
                                : "wrapper-form-item"
                            }
                          >
                            <span className="input-label">{element.label}</span>
                            <Input
                              key={element.value}
                              id={element.id}
                              colorGrey={this.props.colorGrey}
                              name={element.name}
                              type={
                                this._elementType(element, element.type)
                              }
                              disabled={true}
                              defaultValue={this._elementValue(element, element.type)}
                              value={this._elementValue(element, element.type)}
                              onChangeInput={(e) => ""}
                              onFocus={() => {}}
                              onBlur={() => {}}
                              showErrorLabel={false}
                              prepare_dates={{ [element.id]: element.value }}
                              errorLabel={"Este campo no puede estar vacío"}
                              currency_type={this._getCurrencyTypeByField(element, text_fiedls)}
                              hasTooltip={hasTooltip}
                              helperTooltip={[element.label, element.tooltip]}
                              sqft_type={text_fiedls.filter((e) => e.id == "sqft_type")[0]}
                              builded_surface_type={builded_surface_type}
                              resource_key={this.state.resource_key}
                            />
                          </div>
                        );
                      }
                      else if(element.id == "select_accounts"){
                        return (
                          <div key={i+"_"+element.id+"_"+this.props.data.identifier.resourceable_type} className="wrapper-form-item">
                            <SelectMultipleCustodian
                              colorGrey={this.props.colorGrey}
                              setRef={(ref) => (this.selectMultiple = ref)}
                              defaultValue={
                                element.id !== "select_accounts" ?
                                element.value !== "" &&
                                  element.options.filter(
                                    (e) => e.id === element.value
                                  )[0].value : element.value
                              }
                              label={element.label}
                              options={element.options}
                              multiple={element.id === "select_accounts"}
                              openOptions={() =>
                                  this.setState({
                                    modalOptions: !this.state.modalOptions,
                                  })
                              }
                              disabled={element.id !== "select_accounts"}
                              modalOptions={this.state.modalOptions}
                              getOptions={(items) =>
                                  this._getOptions(items, this.state)
                              }
                              // itemsSelected={
                              //   this.state.inputsValue.type !== undefined
                              //     ? this.state.inputsValue.type
                              //     : []
                              // }
                            />
                          </div>                          
                        )
                      } 
                      else {
                        return (
                          <div key={i+"_"+element.id+"_"+this.props.data.identifier.resourceable_type} className="wrapper-form-item">
                            <SelectMultiple
                              colorGrey={this.props.colorGrey}
                              setRef={(ref) => (this.selectMultiple = ref)}
                              defaultValue={
                                element.id !== "select_accounts" ?
                                element.value !== "" &&
                                  element.options.filter(
                                    (e) => e.id === element.value
                                  )[0].value : element.value
                              }
                              label={element.label}
                              options={element.options}
                              multiple={element.id === "select_accounts"}
                              openOptions={() =>
                                  this.setState({
                                    modalOptions: !this.state.modalOptions,
                                  })
                              }
                              disabled={element.id !== "select_accounts"}
                              modalOptions={this.state.modalOptions}
                              getOptions={(items) =>
                                  this._getOptions(items, this.state)
                              }
                              // itemsSelected={
                              //   this.state.inputsValue.type !== undefined
                              //     ? this.state.inputsValue.type
                              //     : []
                              // }
                            />
                          </div>
                        );
                      }
                    }
                  })}

                </div>
              </React.Fragment>
            )}
          
          </div>

          {(this.props.data.ownership_structure ||
            maps.value ||
            (images.options !== undefined && images.options.length > 0)) && (
              <div className="two-columns">
                {/* CAROUSEL */}

                {this.props.carousel_enabled &&
                    images.options !== undefined &&
                    images.options.length > 0 && (
                      this.renderCarousel(images)
                    )}

                {/* @CAROUSEL */}

                {maps.value !== undefined && maps.value.length > 0 && (
                  <DetailContentBlock
                    title={this.props.data.section_titles.location}
                  >
                    <div className="detailTypeOne-wrapper-map">
                      <iframe
                        title="map"
                        width="100%"
                        height="250"
                        frameBorder="0"
                        style={{ border: 0 }}
                        src={`https://maps.google.com/maps?q=${maps.value}&t=&z=16&ie=UTF8&iwloc=&output=embed`}
                        allowFullScreen
                      />
                    </div>
                  </DetailContentBlock>
                )}

                {/* NESTEDLIST */}
                {this.props.data.ownership_structure &&
                    this.props.data.ownership_structure.data[0] &&
                    this.props.data.ownership_structure.data[0].descendants.length >
                    0 && (
                      <div
                        className="detailTypeOne-wrapper-nestedList"
                        style={{ maxHeight: this._calculateHeight() }}
                      >
                        <NestedListOwnership
                          mainClass={"nestedlist--padding"}
                          color={"nestedlist__item--grey"}
                          data={this.props.data.ownership_structure.data}
                          action={(id, url) => this._ownershipAction(id, url)}
                          levelColors={true}
                          square={"nestedlist__item--square"}
                          ownershipId={this.props.history.location.pathname}
                        />
                      </div>
                    )}
                {/* NESTEDLIST */}
              </div>
            )}
        </div>

      )
    }

    transactionContent() {
      return (
        this.props.data.transactions && Object.keys(this.props.data.transactions).length > 0 && (
          <div className="o-margin--bottom-fourtyEight o-margin--top-twentyFour">
            <p className="headingH2 o-margin--bottom-size-l">
              {this.props.language == "es-ES"
                ? "Últimas Transacciones"
                : "Last Transactions"}
              :
            </p>
            <div className="c-table__wrapper">
              <Table dataTable={this.props.data.transactions} />
            </div>
          </div>
        )
      )
    }

    // ownersContent() {
    //   return (
    //     this.props.data.owners !== undefined &&
    //     this.props.data.owners.length > 0 && (
    //
    //       <DetailContentBlock title={this.props.data.section_titles.owners}>
    //         {this.props.data.owners.map((element, i) => {
    //           return (
    //             <MediaBlock
    //               key={"owners_" + i + "_" + element.name}
    //               image={<RenderDocIcon type={element.type} />}
    //               title={element.name}
    //               description={""}
    //               action={() => ""}
    //             />
    //           );
    //         })}
    //       </DetailContentBlock>
    //     )
    //   )
    //
    // }


  // tenantsContent() {
  //   return(
  //     this.props.data.tenants !== undefined &&
  //     this.props.data.tenants.length > 0 && (
  //       <DetailContentBlock title={this.props.data.section_titles.tenants}>
  //         <div className="box-wrapper-color">
  //           {this.props.data.tenants.map((element, i) => {
  //             return (
  //               <div className="box-wrapper-color__content">
  //                 <MediaBlock
  //                   key={"tenants_" + i + "_" + element.name}
  //                   image={<Icon name="Tenant" />}
  //                   title={element.name}
  //                   description={element.office_number}
  //                   action={() => this.props.showTenant(element.url)}
  //                   imageSeparator={true}
  //                   titleFullWidth={true}
  //                   center={true}
  //                 />
  //               </div>
  //             );
  //           })}
  //         </div>
  //       </DetailContentBlock>
  //     )
  //   )
  // }

  // alertsContent() {
  //   return(
  //     this.props.data.alerts !== undefined &&
  //     this.state.btnShowAllItems["alerts"] &&
  //     this.props.data.alerts.length > 0 && (
  //       <DetailContentBlock title={this.props.data.section_titles.alerts}>
  //         <div className="box-wrapper-color">
  //           {this.props.data.alerts
  //               .slice(0, this.state.btnShowAllItems["alerts"].lengthItems)
  //               .map((element, i) => {
  //                 return (
  //                   <div className="box-wrapper-color__content">
  //                     <MediaBlock
  //                       key={"alerts_" + i + "_" + element.title}
  //                       image={
  //                         <RenderDocIcon sizeBig="true" type={element.type} />
  //                       }
  //                       title={element.title}
  //                       description={
  //                         element.end_date !== ""
  //                           ? element.start_date + " - " + element.end_date
  //                           : element.start_date
  //                       }
  //                       action={() => this.props.showAlert(element.id)}
  //                       imageSeparator={true}
  //                       titleFullWidth={true}
  //                       center={true}
  //                     />
  //                   </div>
  //                 );
  //               })}
  //         </div>
  //         {this.props.data.alerts.length > MAX_SHOW && (
  //           <button
  //             className="btn primary"
  //             onClick={() =>
  //                 this._btnShowAllItems(
  //                   "alerts",
  //                   !this.state.btnShowAllItems["alerts"].show,
  //                   this.props.data.alerts.length
  //                 )
  //             }
  //             style={{ marginBottom: "24px" }}
  //           >
  //             <span className="btn-text" style={{ padding: "20px 0" }}>
  //               {!this.state.btnShowAllItems["alerts"].show
  //                 ? "Show all Alerts"
  //                 : "Show less Alerts"}
  //             </span>
  //           </button>
  //         )}
  //       </DetailContentBlock>
  //     )
  //
  //   )
  // }

  // imagesContent() {
  //   return(
  //     this.props.data.images !== undefined &&
  //     this.state.btnShowAllItems["images"] &&
  //     this.props.data.images.length > 0 && (
  //       <DetailContentBlock title={this.props.data.section_titles.images}>
  //         <div className="box-wrapper-color">
  //           {this.props.data.images
  //               .slice(0, this.state.btnShowAllItems["images"].lengthItems)
  //               .map((element, i) => {
  //                 return (
  //                   <div className="box-wrapper-color__content">
  //                     <MediaBlock
  //                       key={"images_" + i + "_" + element.urls.medium}
  //                       image={
  //                         <img
  //                           src={element.urls.medium}
  //                           className="mediaBlock-image size-big"
  //                           alt="contactImage"
  //                         />
  //                       }
  //                       title={element.title}
  //                       // description={element.description}
  //                       rightText={element.size}
  //                       documentType={element.type}
  //                       action={() => this._createImagesToZoom(element)}
  //                       imageSeparator={true}
  //                       titleFullWidth={true}
  //                       center={true}
  //                     />
  //                   </div>
  //                 );
  //               })}
  //         </div>
  //         {this.props.data.images.length > MAX_SHOW && (
  //           <button
  //             className="btn primary"
  //             onClick={() =>
  //                 this._btnShowAllItems(
  //                   "images",
  //                   !this.state.btnShowAllItems["images"].show,
  //                   this.props.data.images.length
  //                 )
  //             }
  //             style={{ marginTop: 24 }}
  //           >
  //             <span className="btn-text" style={{ padding: "20px 0" }}>
  //               {!this.state.btnShowAllItems["images"].show
  //                 ? "Show all Images"
  //                 : "Show less Images"}
  //             </span>
  //           </button>
  //         )}
  //       </DetailContentBlock>
  //     )
  //   )
  //
  // }


  renderCarousel(images) {
    return (
      <div className="wrapper-slider">
        <Slider
          asNavFor={this.state.nav2}
          ref={(slider) => (this.slider1 = slider)}
        >
          {images.options !== undefined &&
              images.options.map((img, index) => {
                return (
                  <img
                    name={img.id}
                    key={index}
                    className="img-carousel"
                    src={img.value}
                    alt=""
                  />
                );
              })}
        </Slider>
        {images.options.length >= 2 && (
          <Slider
            asNavFor={this.state.nav1}
            ref={(slider) => (this.slider2 = slider)}
            slidesToShow={3}
            swipeToSlide={true}
            focusOnSelect={true}
            infinite={images.options.length > 2 ? true : false}
            adaptiveHeight={true}
            centerMode={true}
            centerPadding="0px"
          >
            {images.options !== undefined &&
                images.options.map((img, index) => {
                  return (
                  <div 
                    className="container-img-carousel" 
                    key={`container-img-carousel-${index}`}>
                    <img
                      name={img.id}
                      key={index}
                      className="img-carousel"
                      src={img.value}
                      alt=""
                      />
                  </div>
                );
              })}
          </Slider>
        )}
        {images.options !== undefined &&
            images.options.length >= 2 && (
              <>
                <div
                  onClick={() => this._previous()}
                  className="arrow-container-left"
                >
                  <Icon name="ChevronArrow" orientation="left" />
                </div>
                <div
                  onClick={() => this._next()}
                  className="arrow-container-right"
                >
                  <Icon name="ChevronArrow" orientation="right" />
                </div>
              </>
            )}
      </div>
    )
  }

  renderCarouselTables(images) {
    return (
      <>
        <div className="zoom-images__tools">
          <div
            className="zoom-images__tools-icon"
            onClick={() => this.props.deleteImage([{id: images[this.state.carouselImageSelected][1].value, url : images[this.state.carouselImageSelected][0].value.large}])}
          >
            <Icon
              width={"20px"}
              height={"20px"}
              noWrapper={true}
              color={"#f3b01c"}
              name="Garbage"
              orientation={"right"}
            />
          </div>
        </div>
        <div className="wrapper-slider" style={{display: images.length === 1 ? 'flex' : 'block', justifyContent: 'center'}}>
          {
            images.length === 1 && (
              <img
                className="img-carousel"
                src={images[0][0].value.large}
                alt="carousel image"
              />
            )
          }
          {
            images.length >= 2 && (
              <Slider
                asNavFor={this.state.nav2}
                ref={(slider) => (this.slider1 = slider)}
              >
                {
                  images !== undefined &&  images.map((img, index) => {
                    return (
                      <img
                        key={img[1].value}
                        className="img-carousel"
                        src={img[0].value.large}
                        alt="carousel image"
                      />
                    )
                  })
                }
              </Slider>
            )
          }
          {
            images.length >= 2 && (
              <Slider
                asNavFor={this.state.nav1}
                ref={(slider) => (this.slider2 = slider)}
                slidesToShow={images.length > 2 ? 3 : 2}
                swipeToSlide={true}
                focusOnSelect={true}
                infinite={images.length > 4 ? true : false}
                adaptiveHeight={true}
                centerMode={true}
                centerPadding="0px"
              >
                {
                  images !== undefined && images.map((img, index) => {
                    return (
                      <div className="container-img-carousel">
                        <img
                          name={img[1].value}
                          key={img[1].value}
                          className="img-carousel"
                          src={img[0].value.medium}
                          alt=""
                        />
                      </div>
                    );
                  })
                }
              </Slider>
            )
          }
          {
            images.length >= 2 && (
              <>
                <div
                  onClick={() => this._previous()}
                  className="arrow-container-left"
                >
                  <Icon name="ChevronArrow" orientation="left" />
                </div>
                <div
                  onClick={() => this._next()}
                  className="arrow-container-right"
                >
                  <Icon name="ChevronArrow" orientation="right" />
                </div>
              </>
            )
          }
        </div>
      </>
    )
  }


  render() {
    let title = "";
    let images = "";
    let text_fiedls = "";
    let maps = "";
    if (Object.keys(this.props.data).length !== 0) {
      let title_found = this.getInputs().filter((input) => input.id === "name");
      title =
        title_found.length === 0 ? this.props.data.title : title_found[0].value;

      let images_found = this.getInputs().filter(
        (input) => input.id === "images"
      );
      images = images_found.length === 0 ? [] : images_found[0];

      let text_fiedls_found = this.getInputs().filter(
        (input) =>
          input.id !== "images" && !input.hidden && this._parentSelected(input)
      );
      text_fiedls = text_fiedls_found.length === 0 ? [] : text_fiedls_found;

      let maps_found = this.getInputs().filter(
        (input) => input.id === "address_description"
      );
      maps = maps_found.length === 0 ? [] : maps_found[0];
    }
    
    let inputData = text_fiedls.filter( (item) =>
        item.value !== "" && item.id != "title" && item.id != "name" && !['sqft_type', 'builded_surface_type', 'link_asset','link_liability', 'currency_type', 'crypto_converted', 'currency_type_purchase_price'].includes(item.id)
    )
    
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
    };
    let languageUserAgent = (/^es/).test(this.props.language) ? 'es' : 'en';
    return (
      <>
        <div className="detailTypeOne-header" style={{justifyContent: 'space-between', alignItems: "center", flexDirection: 'row'}}>
          <span className="headingH2">{title}</span>
          { this.props.data && this.props.data.domum_navigation_link && 
            <div key={"_"+1212+"_"+this.props.data.identifier.resourceable_type} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <span className="input-label">{languageUserAgent === "es" ? "Navega a Domum App" : "Go to Domum App"}</span>
              <div> 
                <a target="_blank" href={this.props.data.domum_navigation_link}>
                  <LogoDomum />
                </a>
              </div>
            </div>
          }
        </div>

        {
          (this.props.data.tabs && this.props.data.tabs.length > 0) && (
            <Tabs 
              tabs={this.props.data.tabs} 
              onClick={(tab, index) => this.handleTabsContent(tab, index)}
              tabActive={this.state.screen_step_index}
              location={this.props.data.historical_date}
              hasHistorical={this.props.data.historical_date}
            />
          )
        }

        {this.state.contentStep === 'main' && (
        <div className="c-tabs__content is-selected">
            { this.props.data.historical_date && 
              <div style={{marginTop: -20, marginBottom: 10,}}>
                <span className="input-label" style={{fontSize: 14, color: '#005774'}}>Historical Date: {this.props.data.historical_date}</span>
              </div>
            }
            {this.props.data.extra_description &&
              <div className="textarea" style={{marginBottom: 10}}>
                <span className="input-label" style={{color: '#005774'}}>Description</span>
                <div className="">
                  <textarea style={{minHeight: 66}} disabled="true" name="alternative_description" className=" input grey-placeholder grey">{this.props.data.extra_description}</textarea>
                </div>
              </div>
            }
            
            {this.mainContent(maps, inputData, images, text_fiedls, languageUserAgent) }
            {
              this.props.data.table_custodian && title !== "Directly Owned" &&
               <TableDropdown 
                fromSearch={this.state.fromSearch}
                key={"custodian_KEY"} 
                selectedIndex={-1} 
                dataTable={this.props.data.table_custodian.table} 
                pieSliceSelected={-1}
                history={this.props.history} 
                onClickSecurity={(id) => this._onClickSecurity(id)}
                security_info={this.props.data.security_info}  
                // search={(text)=> console.log(text)}
              />
            }
            {
              this.props.data.table_custodian && title === "Directly Owned" &&
              <div className="c-tabs__content is-selected">
                <div className="c-tabs__content-top">
                <TableAlternatives
                  history={this.props.history}
                  key={"alternatives"}
                  selectedIndex={-1}
                  dataTable={this.props.data.table_custodian.table}
                  pieSliceSelected={-1}
                />
                </div>
              </div>
            }
            
            {
              this.props.data.graph_custodian && Object.keys(this.props.data.graph_custodian).length > 0 && this.props.data.graph_custodian.last_month && title !== "Directly Owned" &&
              <LineHighChart
                key={"graph_t"}
                type="custodian"
                updateData={(itemsSelected, data_temp) => this.setState({itemsSelected: itemsSelected, graph_key: "graph_"+itemsSelected})}
                currentData={this.props.data.graph_custodian.last_month}
                dates={this.props.data.graph_custodian.dates}
                allCustodians={this.props.data.graph_all}
                currentCustodianName={title}
                centerLabel={true}
                languageUserAgent={languageUserAgent}
                setFilterType={(type) => this.setState({setFilterType: type, fromSearch: ""})}
                yAxisTitle={languageUserAgent.includes("es") ? "Rendimiento" : "Performance"}
                extra_title={this.props.data.extra_title}
              />
            }

            {/* {
              this.state.setFilterType === "last_month" && this.props.data.graph_custodian && this.props.data.graph_custodian.last_31 && 
              <div className="c-charts__bottom-text-wrapper" style={{marginBottom: 12, marginLeft: 30}}> 
              <div className="c-charts__bottom-text">
               <li style={{listStyle: 'none'}}><p dangerouslySetInnerHTML={{ __html: this.props.data.preheader }} className="c-charts__bottom-text-paragraph"></p> </li>
               
               <li style={{listStyle: 'none'}}><p className="c-charts__bottom-text-paragraph">{this.props.data.graph_custodian.last_31.high}</p> </li>
               
               <li style={{listStyle: 'none'}}><p className="c-charts__bottom-text-paragraph">{this.props.data.graph_custodian.last_31.low}</p></li>
              </div>
              </div>
            }
            */}

            {
              this.props.data.graph_account && this.props.data.graph_account.last_month &&  
                <LineHighChart
                  key={"graph_t"}
                  type="account"
                  updateData={(itemsSelected, data_temp) => this.setState({itemsSelected: itemsSelected, graph_key: "graph_"+itemsSelected})}
                  currentData={this.props.data.graph_account.last_month}
                  dates={this.props.data.graph_account.dates}
                  allCustodians={{}}
                  accountData={this.props.data.graph_account}
                  currentCustodianName={title}
                  centerLabel={true}
                  languageUserAgent={languageUserAgent}
                  yAxisTitle={languageUserAgent.includes("es") ? "Rendimiento" : "Performance"}
                  setFilterType={(type) => this.setState({setFilterType: type, fromSearch: ""})}
                  extra_title={this.props.data.extra_title}
                  preheader={ this.state.setFilterType === "last_month" && this.props.data.graph_account && this.props.data.graph_account.last_31 && this.props.data.preheader}
                />
            }
            
            <div className="c-charts__bottom-text-wrapper">

              { this.props.data.graph_account && this.props.data.graph_account.top5[this.state.setFilterType] && (
                <div className="c-charts__bottom-text">
                  <p className="c-charts__bottom-text-title">Top 5 Performers</p>
                  {
                  this.props.data.graph_account.top5[this.state.setFilterType].map((t5) => {
                    return (
                      <li style={{cursor: 'pointer'}} onClick={() => this._topBottomClicked(t5.entity_id)} className="c-charts__bottom-text-paragraph">{t5.name} ({t5.value})</li>
                    )
                  })
                }
                </div>

              )}

              { this.props.data.graph_account && this.props.data.graph_account.bottom5[this.state.setFilterType] && (
                <div className="c-charts__bottom-text">
                  <p className="c-charts__bottom-text-title" style={{color: "#f3b01c", borderBottom: '1px solid #f3b01c'}}>Bottom 5 Performers</p>
                  {
                  this.props.data.graph_account.bottom5[this.state.setFilterType].map((t5) => {
                    return (
                      <li onClick={() => this._topBottomClicked(t5.entity_id)} style={{color: "#f3b01c", cursor: 'pointer'}} className="c-charts__bottom-text-paragraph">{t5.name} ({t5.value})</li>
                    )
                  })
                }
                </div>
              )}
            </div>
          </div>
        )}
        {this.state.contentStep === 'transactions' && (
        <div className="c-tabs__content is-selected">
            {this.transactionContent()}
          </div>
        )}
        {this.state.contentStep === 'stake_holders' && this.props.data.tablesv2.stake_holders && (
          <div className="c-tabs__content is-selected">
            <div className="c-tabs__content-top">
              <Table 
                key={"stake_holders"+this.props.data.tablesv2.stake_holders.current_page+"search"+this.state.search_text["stake_holders"]}              
                dataTable={this.props.data.tablesv2.stake_holders} 
                showSearcher={this.props.data.tablesv2.stake_holders.total_pages !== 1 || (this.state.search_text["stake_holders"] !== undefined && this.state.search_text["stake_holders"] !== "")} 
                action={{
                  title: internalization[languageUserAgent].action, 
                  show: (el) => this.props.showStakeHolder(el.url, false),
                  edit: (el) => this.props.showStakeHolder(el.url, true)
                }} 
                searchText={this.state.search_text["stake_holders"]}
                search={(text)=> this.props.paginateTab({text: text, source: 'stake_holders', page: 1, type: "get_stake_holders"}, () => this.setState({search_text: {...this.state.search_text, ["stake_holders"]:text} }), () => "")}
              />
            </div>
            {
              this.props.data.tablesv2.stake_holders.rows.length > 0 && this.props.data.tablesv2.stake_holders.total_pages > 1 && (
              <div className="c-tabs__content-bottom">
                <Pagination
                  goTo={page => {
                    this.props.paginateTab({text: this.state.search_text["stake_holders"], source: 'stake_holders', page: page, type: "get_stake_holders"}, () => "", () => "")
                    // goToPage(page)
                  }}
                  pages={Array.from({ length: this.props.data.tablesv2.stake_holders.total_pages }, (v, k) => k + 1)}
                  currentPage={this.props.data.tablesv2.stake_holders.current_page}
                />
              </div>
              )
            }            
          </div>                             
        )}
        {this.state.contentStep === 'beneficiary_accounts' && this.props.data.tablesv2.beneficiary_accounts && (
          <div className="c-tabs__content is-selected">
            <div className="c-tabs__content-top">
              <Table 
                key={"beneficiary_accounts"+this.props.data.tablesv2.beneficiary_accounts.current_page+"search"+this.state.search_text["beneficiary_accounts"]}              
                dataTable={this.props.data.tablesv2.beneficiary_accounts} 
                showSearcher={this.props.data.tablesv2.beneficiary_accounts.total_pages !== 1 || (this.state.search_text["beneficiary_accounts"] !== undefined && this.state.search_text["beneficiary_accounts"] !== "")} 
                action={{
                  title: internalization[languageUserAgent].action, 
                  show: (el) => this.props.showStakeHolder(el.url, false),
                  edit: (el) => this.props.showStakeHolder(el.url, true)
                }} 
                searchText={this.state.search_text["beneficiary_accounts"]}
                search={(text)=> this.props.paginateTab({text: text, source: 'beneficiary_accounts', page: 1, type: "get_beneficiary_accounts"}, () => this.setState({search_text: {...this.state.search_text, ["beneficiary_accounts"]:text} }), () => "")}
              />
            </div>
            {
              this.props.data.tablesv2.beneficiary_accounts.rows.length > 0 && this.props.data.tablesv2.beneficiary_accounts.total_pages > 1 && (
              <div className="c-tabs__content-bottom">
                <Pagination
                  goTo={page => {
                    this.props.paginateTab({text: this.state.search_text["beneficiary_accounts"], source: 'beneficiary_accounts', page: page, type: "get_beneficiary_accounts"}, () => "", () => "")
                    // goToPage(page)
                  }}
                  pages={Array.from({ length: this.props.data.tablesv2.beneficiary_accounts.total_pages }, (v, k) => k + 1)}
                  currentPage={this.props.data.tablesv2.beneficiary_accounts.current_page}
                />
              </div>
              )
            }            
          </div>                             
        )}
        {this.state.contentStep === 'owners' && this.props.data.tablesv2.owners &&(
          <div className="c-tabs__content is-selected">
            <div className="c-tabs__content-top">
            <Table
              key={"owners"+this.props.data.tablesv2.owners.current_page+"search"+this.state.search_text["owners"]}                           
              dataTable={this.props.data.tablesv2.owners} 
              showSearcher={this.props.data.tablesv2.owners.total_pages !== 1 || (this.state.search_text["owners"] !== undefined && this.state.search_text["owners"] !== "")}                            
              action={{
                title: internalization[languageUserAgent].action,
                show: () => '',
                edit: () => ''
              }} 
              searchText={this.state.search_text["owners"]}
              search={(text)=> this.props.paginateTab({text: text, source: 'owners', page: 1, type: "get_owners"}, () => this.setState({search_text: {...this.state.search_text, ["owners"]:text} }), () => "")}
            />
            </div>
            {
              this.props.data.tablesv2.owners.rows.length > 0 && this.props.data.tablesv2.owners.total_pages > 1 && (
              <div className="c-tabs__content-bottom">
                <Pagination
                  goTo={page => {
                    this.props.paginateTab({text: this.state.search_text["owners"], source: 'owners', page: page, type: "get_owners"}, () => "", () => "")
                    // goToPage(page)
                  }}
                  pages={Array.from({ length: this.props.data.tablesv2.owners.total_pages }, (v, k) => k + 1)}
                  currentPage={this.props.data.tablesv2.owners.current_page}
                />
              </div>
              )
            }            
          </div>                             
        )}
        {this.state.contentStep === 'linked' && this.props.data.tablesv2.linked && (
          <div className="c-tabs__content is-selected">
            <div className="c-tabs__content-top">
            <Table 
              key={"linked"+this.props.data.tablesv2.linked.current_page+"search"+this.state.search_text["linked"]}            
              dataTable={this.props.data.tablesv2.linked} 
              showSearcher={this.props.data.tablesv2.linked.total_pages !== 1 || (this.state.search_text["linked"] !== undefined && this.state.search_text["linked"] !== "")}                            
              action={{
                title: internalization[languageUserAgent].action, 
                show: (el) => this.setState({ linkedResource: el, showAlertAsk: true, })
              }} 
              searchText={this.state.search_text["linked"]}
              search={(text)=> this.props.paginateTab({text: text, source: 'linked', page: 1, type: "get_linked_assets"}, () => this.setState({search_text: {...this.state.search_text, ["linked"]:text} }), () => "")}
              all_ellipsis={true}
            />
            </div>
            {
              this.props.data.tablesv2.linked.rows.length > 0 && this.props.data.tablesv2.linked.total_pages > 1 &&(
              <div className="c-tabs__content-bottom">
                <Pagination
                  goTo={page => {
                    this.props.paginateTab({text: this.state.search_text["linked"], source: 'linked', page: page, type: "get_linked_assets"}, () => "", () => "")
                    // goToPage(page)
                  }}
                  pages={Array.from({ length: this.props.data.tablesv2.linked.total_pages }, (v, k) => k + 1)}
                  currentPage={this.props.data.tablesv2.linked.current_page}
                />
              </div>
              )
            }
          </div>                             
        )}
        {this.state.contentStep === 'accounts' && this.props.data.tablesv2.accounts && (
          <div className="c-tabs__content is-selected">
            <div className="c-tabs__content-top">
            <Table 
              key={"accounts"+this.props.data.tablesv2.accounts.current_page+"search"+this.state.search_text["accounts"]}            
              dataTable={this.props.data.tablesv2.accounts} 
              showSearcher={this.props.data.tablesv2.accounts.total_pages !== 1 || (this.state.search_text["accounts"] !== undefined && this.state.search_text["accounts"] !== "")}                            
              action={{
                title: internalization[languageUserAgent].action, 
                show: (el) => this.setState({ linkedResource: el, showAlertAsk: true, })
              }} 
              searchText={this.state.search_text["accounts"]}
              search={(text)=> this.props.paginateTab({text: text, source: 'accounts', page: 1, type: "get_accounts"}, () => this.setState({search_text: {...this.state.search_text, ["accounts"]:text} }), () => "")}
              all_ellipsis={true}
            />
            </div>
            {
              this.props.data.tablesv2.accounts.rows.length > 0 && this.props.data.tablesv2.accounts.total_pages > 1 &&(
              <div className="c-tabs__content-bottom">
                <Pagination
                  goTo={page => {
                    this.props.paginateTab({text: this.state.search_text["accounts"], source: 'accounts', page: page, type: "get_accounts"}, () => "", () => "")
                    // goToPage(page)
                  }}
                  pages={Array.from({ length: this.props.data.tablesv2.accounts.total_pages }, (v, k) => k + 1)}
                  currentPage={this.props.data.tablesv2.accounts.current_page}
                />
              </div>
              )
            }
          </div>                             
        )}        
        {this.state.contentStep === 'documents' && this.props.data.tablesv2.documents &&(
          <div className="c-tabs__content is-selected">
            <div className="c-tabs__content-top">
            <Table 
              key={"documents"+this.props.data.tablesv2.documents.current_page+"search"+this.state.search_text["documents"]}            
              dataTable={this.props.data.tablesv2.documents}
              showSearcher={this.props.data.tablesv2.documents.total_pages !== 1 || (this.state.search_text["documents"] !== undefined && this.state.search_text["documents"] !== "")}                             
              action={{
                title: internalization[languageUserAgent].action,
                show: (el) => this.props.showDocument(el.url, false),
                edit: (el) => this.props.showDocument(el.url, true)
              }} 
              searchText={this.state.search_text["documents"]}
              search={(text)=> this.props.paginateTab({text: text, page: 1, source: 'documents', type: "get_documents"}, () => this.setState({search_text: {...this.state.search_text, ["documents"]:text} }), () => "")}
              all_ellipsis={true} 
              hasFirstColumnBig={true}
            />
            </div>
            {
              this.props.data.tablesv2.documents.rows.length > 0 && this.props.data.tablesv2.documents.total_pages > 1 && (
              <div className="c-tabs__content-bottom">
                <Pagination
                  goTo={page => {
                    this.props.paginateTab({text: this.state.search_text["documents"], source: 'documents', page: page, type: "get_documents"}, () => "", () => "")
                    // goToPage(page)
                  }}
                  pages={Array.from({ length: this.props.data.tablesv2.documents.total_pages }, (v, k) => k + 1)}
                  currentPage={this.props.data.tablesv2.documents.current_page}
                />
              </div>
              )
            }
          </div>                             
        )}
        {this.state.contentStep === 'contacts' && this.props.data.tablesv2.contacts && (
          <div className="c-tabs__content is-selected">
            <div className="c-tabs__content-top">
            <Table 
              key={"contact"+this.props.data.tablesv2.contacts.current_page+"search"+this.state.search_text["contacts"]}
              dataTable={this.props.data.tablesv2.contacts}
              showSearcher={this.props.data.tablesv2.contacts.total_pages !== 1 || (this.state.search_text["contacts"] !== undefined && this.state.search_text["contacts"] !== "")}               
              action={{
                title: internalization[languageUserAgent].action,
                show: (el) => this.props.showContact(el.url, false),
                edit: (el) => this.props.showContact(el.url, true),
                unlink: (el) => this.props.unlinkContact(el)
              }} 
              searchText={this.state.search_text["contacts"]}
              search={(text)=> this.props.paginateTab({text: text, source: 'contacts', page: 1, type: "get_contacts"}, () => this.setState({search_text: {...this.state.search_text, ["contacts"]:text} }), () => "")}
              all_ellipsis={true}
            />
            </div>
            {
              this.props.data.tablesv2.contacts.rows.length > 0 && this.props.data.tablesv2.contacts.total_pages > 1 && (
              <div className="c-tabs__content-bottom">
                <Pagination
                  goTo={page => {
                    this.props.paginateTab({text: this.state.search_text["contacts"], source: 'contacts', page: page, type: "get_contacts"}, () => "", () => "")
                    // goToPage(page)
                  }}
                  pages={Array.from({ length: this.props.data.tablesv2.contacts.total_pages }, (v, k) => k + 1)}
                  currentPage={this.props.data.tablesv2.contacts.current_page}
                />
              </div>
              )
            }
          </div>                             
        )}
        {this.state.contentStep === 'tenants' && this.props.data.tablesv2.tenants &&  (
          <div className="c-tabs__content is-selected">
            <div className="c-tabs__content-top">
            <Table
              key={"tenants"+this.props.data.tablesv2.tenants.current_page+"search"+this.state.search_text["tenants"]}            
              dataTable={this.props.data.tablesv2.tenants}
              showSearcher={this.props.data.tablesv2.tenants.total_pages !== 1 || (this.state.search_text["tenants"] !== undefined && this.state.search_text["tenants"] !== "")}
              action={{
                title: internalization[languageUserAgent].action,
                show: (el) => this.props.showTenant(el.url, false),
                edit: (el) => this.props.showTenant(el.url, true)
              }} 
              searchText={this.state.search_text["tenants"]}
              search={(text)=> this.props.paginateTab({text: text, source: 'tenants', page: 1, type: "get_tenants"}, () => this.setState({search_text: {...this.state.search_text, ["tenants"]:text} }), () => "")}
            />
            </div>
            {
              this.props.data.tablesv2.tenants.rows.length > 0 && this.props.data.tablesv2.tenants.total_pages > 1 &&(
              <div className="c-tabs__content-bottom">
                <Pagination
                  goTo={page => {
                    this.props.paginateTab({text: this.state.search_text["tenants"], source: 'tenants', page: page, type: "get_tenants"}, () => "", () => "")
                    // goToPage(page)
                  }}
                  pages={Array.from({ length: this.props.data.tablesv2.tenants.total_pages }, (v, k) => k + 1)}
                  currentPage={this.props.data.tablesv2.tenants.current_page}
                />
              </div>
              )
            }
          </div>                             
        )}
        {this.state.contentStep === 'alerts' && this.props.data.tablesv2.alerts &&  (
          <div className="c-tabs__content is-selected">
            <div className="c-tabs__content-top">
            <Table 
              key={"alerts"+this.props.data.tablesv2.alerts.current_page+"search"+this.state.search_text["alerts"]}            
              dataTable={this.props.data.tablesv2.alerts}
              showSearcher={this.props.data.tablesv2.alerts.total_pages !== 1 || (this.state.search_text["alerts"] !== undefined && this.state.search_text["alerts"] !== "")}               
              action={{
                title: internalization[languageUserAgent].action, 
                show: (el) => this.props.showAlert(el.id, false),
                edit: (el) => this.props.showAlert(el.id, true)
              }} 
              searchText={this.state.search_text["alerts"]}
              search={(text)=> this.props.paginateTab({text: text, source: 'alerts', page: 1, type: "get_alerts"}, () => this.setState({search_text: {...this.state.search_text, ["alerts"]: text}} ), () => "")}
              all_ellipsis={true} 
            />
            </div>
            {
              this.props.data.tablesv2.alerts.rows.length > 0 && this.props.data.tablesv2.alerts.total_pages > 1 && (
              <div className="c-tabs__content-bottom">
                <Pagination

                  goTo={page => {
                    this.props.paginateTab({text: this.state.search_text["alerts"], source: 'alerts', page: page, type: "get_alerts"}, () => "", () => "")
                    // goToPage(page)
                  }}
                  pages={Array.from({ length: this.props.data.tablesv2.alerts.total_pages }, (v, k) => k + 1)}
                  currentPage={this.props.data.tablesv2.alerts.current_page}
                />
              </div>
              )
            }
          </div>                             
        )}
        {this.state.contentStep === 'images' &&  this.props.data.tablesv2.images &&(
          <div className="c-tabs__content is-selected">
            {this.renderCarouselTables(this.props.data.tablesv2.images.rows)}
          </div>                             
        )}
        {this.state.contentStep === 'by_account' &&  this.props.data.tablesv2.by_account &&(
          <div className="c-tabs__content is-selected">
            <TableMultipleDropdown 
              key={"custodian_KEY"} 
              selectedIndex={-1} 
              dataTable={this.props.data.tablesv2.by_account.table} 
              pieSliceSelected={-1}
              history={this.props.history}   
              setMainTab={() => {this.props.handleTabChange && this.props.handleTabChange(0); this.setState({contentStep: "main", screen_step_index: 0})} }                                      
              // search={(text)=> console.log(text)}
            />
          </div>                             
        )}
        {this.state.contentStep === 'valuations' && this.props.data.tablesv2.valuations && (
          <div className="c-tabs__content is-selected">
            <div className="c-tabs__content-top">
            <Table 
              key={"valuation"+this.props.data.tablesv2.valuations.current_page+"search"+this.state.search_text["valuations"]}            
              dataTable={this.props.data.tablesv2.valuations}
              showSearcher={this.props.data.tablesv2.valuations.total_pages !== 1 || (this.state.search_text["valuations"] !== undefined && this.state.search_text["valuations"] !== "")}              
              action={{
                title: internalization[languageUserAgent].action,
                show: (el) => this.props.openValuation(el),
                // edit: (el) => this.props.openValuation(el)
              }}
              searchText={this.state.search_text["valuations"]}
              search={(text)=> this.props.paginateTab({text: text, source: 'valuations', page: 1, type: "get_valuations"}, () => this.setState({search_text: {...this.state.search_text, ["valuations"]: text}}), () => "")}
            />
            </div>
            {
              this.props.data.tablesv2.valuations.rows.length > 0 && this.props.data.tablesv2.valuations.total_pages > 1 &&(
              <div className="c-tabs__content-bottom">
                <Pagination
                  goTo={page => {
                    this.props.paginateTab({text: this.state.search_text["valuations"], source: 'valuations', page: page, type: "get_valuations"}, () => "", () => "")
                    // goToPage(page)
                  }}
                  pages={Array.from({ length: this.props.data.tablesv2.valuations.total_pages }, (v, k) => k + 1)}
                  currentPage={this.props.data.tablesv2.valuations.current_page}
                />
              </div>
              )
            }
          </div>                             
        )}
        {this.state.contentStep === 'activity' && this.props.data.tablesv2.activity && (
          <div className="c-tabs__content is-selected">
            <div className="c-tabs__content-top">
            <Table dataTable={this.props.data.tablesv2.activity}
              key={"activity"+this.props.data.tablesv2.activity.current_page+"search"+this.state.search_text["activity"]}     
              showSearcher={this.props.data.tablesv2.activity.total_pages !== 1 || (this.state.search_text["activity"] !== undefined && this.state.search_text["activity"] !== "")}              
              action={{
                title: "", 
                show: "",
                edit: ""
              }}
              searchText={this.state.search_text["activity"]}
              search={(text)=> this.props.paginateTab({text: text, source: 'activity', page: 1, type: "get_alternative_activity"}, () => this.setState({search_text: {...this.state.search_text, ["activity"]: text}}), () => "")}
              special={true}           
            />
            </div>
            {
              this.props.data.tablesv2.activity.rows.length > 0 && this.props.data.tablesv2.activity.total_pages > 1 && (
              <div className="c-tabs__content-bottom">
                <Pagination
                  goTo={page => {
                    this.props.paginateTab({text: this.state.search_text["activity"], source: 'activity', page: page, type: "get_alternative_activity"}, () => "", () => "")
                    // goToPage(page)
                  }}
                  pages={Array.from({ length: this.props.data.tablesv2.activity.total_pages }, (v, k) => k + 1)}
                  currentPage={this.props.data.tablesv2.activity.current_page}
                />
              </div>
              )
            }
          </div>                             
        )}        
      {this.props.data.tables !== undefined &&
            this.props.data.tables.map((table, i) => {
              return (
                <div
                  key={i}
                  className={
                    this.props.data.tables.length !== i + 1 ? "wrapper-separator" : ""
                  }
                >
                  <TableBlock
                    id_key={i}
                    data={table}
                    lengthShowActive={i === 0 ? false : table.rows.length > 15}
                    notScroll={
                      i === 0 &&
                        this.props.data.identifier &&
                        ["ReceivableAccount", "Liability"].includes(
                          this.props.data.identifier.resourceable_type
                        )
                    }
                    showBtn={i === 0 ? false : table.rows.length > 15}
                    secondTable={
                      this.props.data.tables.length !== i + 1 ? false : true
                    }
                    detail={true}
                  />
                </div>
              );
            })}

        {this.state.showImageModal === true && (
          <Modal
            isShowed={this.state.showImageModal}
            modalType="zoom-images"
            showBackButton={true}
            backAction={(e) => this.setState({ showImageModal: false })}
            showDeleteButton={false}
            buttonText={this.props.internalization.buttons.back}
            paddingS={true}
            paddingBody={true}
            heightAuto={true}
          >
            {imagesToZoom.map((img, index) => {
              return (
                <>
                  <div className="zoom-images__tools">
                    <div
                      className="zoom-images__tools-icon"
                      onClick={() =>
                          this.props.deleteImage(this.state.imagesToZoom)
                      }
                    >
                      <Icon
                        width={"20px"}
                        height={"20px"}
                        noWrapper={true}
                        color={"#f3b01c"}
                        name="Garbage"
                        orientation={"right"}
                      />
                    </div>
                  </div>
                  <div className="zoom-images__wrapper">
                    <div className="zoom-images__wrapper-img">
                      <img
                        name={img.id}
                        key={index}
                        className="img-carousel"
                        src={img.url}
                        alt=""
                      />
                    </div>
                  </div>
                </>
              );
            })}
          </Modal>
        )}


        {this.state.showAlertAsk && (
          <AlertModal
            isShowed={this.state.showAlertAsk}
            title={
              this.props.insurance_user
                ? this.props.internalization.alerts.navigate_to_asset
                : this.props.internalization.alerts.navigate_to_or_delete
            }
            // msg={"Mensaje"}
            action={() => {
              this.setState({ showAlertAsk: false });
              this.props.navigateTo(this.state.linkedResource);
            }}
            textButton={this.props.internalization.alerts.navigate_to}
            textButtonAlertColor={false}
            cancelButton={true}
            cancelTextButton={this.props.internalization.buttons.cancel}
            cancelButtonAlertColor={false}
            actionCancelButton={() => this.setState({ showAlertAsk: false })}
            thirdButton={this.props.insurance_user ? false : true}
            textButtonAlertColorThird={true}
            textButtonThird={this.props.internalization.buttons.unlink}
            actionThirdButton={() => {
              this.setState({ showAlertAsk: false });
              this.props.deleteLinkedResource(this.state.linkedResource);
            }}
          />
        )}

        {this.state.showAlert && (
          <AlertModal
            isShowed={this.state.showAlert}
            title={this.props.internalization.alerts.navigate_to_asset}
            // msg={"Mensaje"}
            action={() => this.props.navigateTo(this.state.linkedResource)}
            textButton={"Ok"}
            textButtonAlertColor={true}
            cancelButton={true}
            cancelTextButton={"Cancel"}
            cancelButtonAlertColor={false}
            actionCancelButton={() => this.setState({ showAlert: false })}
          />
        )}
      </>
    );
  }
}

export default DetailTypeOne;