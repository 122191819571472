// Dependencies
import React from "react";
import { connect } from "react-redux";
import { internalization } from "../../constants";

// Actions
import { getBCIndex } from "../../actions/balance";
import { getFilteredDataBarsGraph } from "../../actions/home";
import { setCurrentBreadcrumb } from "../../actions/session";

// Components
import Loading from "../../components/loading";
import Icon from "../../components/icons";
import StackHeader from "../../components/stackHeader";
import ContentBlock from "../../components/contentBlock";
import IconToolBox from "../../components/iconsToolBox";
import PieGraphic from "../../components/pieGraphic";
import PieHighcharts from "../../components/PieHighcharts";

// import LineGraphic from "../../components/lineGraphic";
import BarsGraphic from "../../components/barsGraphic";
import {canManage, canRead} from '../../helpers/permits'
import TreeGraphic from "../../components/treeGraphic";
import ActionSheet from "../../components/actionSheet";

import treeGraphic_fake from '../../assets/images/treegraphic_fake.png';
import barsGraphic_fake from '../../assets/images/barGraphic_fake.png';
import pieGraphick_fake from '../../assets/images/pieGraphick_fake.png';


class BalanceIndexScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolling: false,
      treeGraphHeightReady: false,
      pieAssetsColorScale: [],
      pieLiabilitiesColorScale: [],
      loading: true,
      filteredDataBarsGraph: {},
      options_action_sheet_short_cut: []
    };

    this.actionSheet = React.createRef();
  }

  _setPieColorsScale() {
    let assetsColors = this.props.balance_index.pie_graphic_assets.data.map(
      element => element.color
    );

    let liabilitiesColors = this.props.balance_index.pie_graphic_liabilities.data.map(
      element => element.color
    );

    this.setState({
      pieAssetsColorScale: assetsColors,
      pieLiabilitiesColorScale: liabilitiesColors
    });
  }

  _success() {
    this._fillActionSheetStart();
    this.setState({
      loading: false
    });
    this._setPieColorsScale();
    if(window.top.location.hash != ""){
      window.location = window.top.location.hash
    }
    let languageUserAgent = (/^es/).test(this.props.user.language) ? 'es' : 'en';    
    this.props.setSpecialParent([{title: internalization[languageUserAgent].models.home, code: "home", url: "/home"}, {title: internalization[languageUserAgent].models.balance, code: "balance_index", url: "/balance-index"}])
    this.props.setCurrentBreadcrumb([{title: internalization[languageUserAgent].models.home, code: "home", url: "/home"}, {title: internalization[languageUserAgent].models.balance, code: "balance_index", url: "/balance-index"}])    
    this.props.getBreadcrumbs({
      assetUrl: "/balance-index",
      url:"/balance-index",
      assetTitle: ""
    });
  }

  _isSubcategories(item){
    
    if(item.form !== undefined){
      if(item.code === "life_insurance"){
        debugger
        if(this.props.bulltick_insurance_agency && this.props.real_user_insurance){
          return false
        }else if(this.props.bulltick_insurance_agency && !this.props.real_user_insurance) {
          return true
        }else {
          return false
        }
      }else {
        return false
      }
      
    }else {
      return true
    }
  }

  _fillActionSheetStart(){
    let options_action_sheet_short_cut = this.props.balance_index && this.props.balance_index.tree_graphic_data && this.props.balance_index.tree_graphic_data.short_cuts && this.props.balance_index.tree_graphic_data.short_cuts.map((item) => {
        return {
            type: "icon button",
            iconName: "Close",
            code: item.code,
            message: item.title,
            onClick: () => {  this._isSubcategories(item) ? this._showSubcategoriesSheet(item) : this.gotToAsset(item);this.actionSheet.current.show() },
        }
    })
    options_action_sheet_short_cut.push({
          type: "noIcon button",
          message: this.props.internalization.buttons.cancel,
          close: true,
          danger: true
        })

        
        this.setState({
          options_action_sheet_short_cut: options_action_sheet_short_cut
        })
  }

  _showSubcategoriesSheet(item){
    
    var that = this;
    let options_action_sheet_short_cut = item.subcategories.map((item_subcategory) => {
        return {
            type: "icon button",
            iconName: "Close",
            message: item_subcategory.title,
            onClick: () => { 
              if(item_subcategory.form !== undefined){
                this.gotToAsset(item_subcategory);
                this.actionSheet.current.show()
                this._restartActionSheet()
              }else {
                this.props.history.push("balance-show", {
                  url: item_subcategory.url, open_actions: true
                });
                this.actionSheet.current.show();
                this._restartActionSheet()
              } },
        }
    })
    options_action_sheet_short_cut.push({
          type: "noIcon button",
          message: this.props.internalization.buttons.cancel,
          close: true,
          danger: true,
          onClick: () => this._restartActionSheet()
        })
  
    this.setState({ options_action_sheet_short_cut: options_action_sheet_short_cut })
    setTimeout(function () { that.actionSheet.current.show(); }, 100);
  }

  _prepareAddPlusLink(item){
    let multiple_new = this.props.balance_index.tree_graphic_data.multiple_new[item.code]
    let associated_elements = []
      try{
        associated_elements = [{add_document: multiple_new["form_document"].add_document}, {add_contact: multiple_new["form_contact"].add_contact}]
        if(multiple_new["form_alerts"] && Object.keys(multiple_new["form_alerts"]).length > 0){
          associated_elements.push({add_alert: multiple_new["form_alerts"].add_alerts})
        }
      }catch(e){

      }
      return associated_elements;
  }

  gotToAsset(item){
    this.props.history.push('balance-new', {

      form: item.form.add_asset.inputs, 
      resource_key: item.form.add_asset.resource_key, 
      resource_constant: item.form.add_asset.resource_constant, 
      modal: item.form.add_asset.modal, 
      url: item.url, 
      method: "POST", 
      code: "add_asset", 
      from: "BalanceIndexScreen",
      resource_constant: item.code ? this.props.balance_index.tree_graphic_data.multiple_new[item.code].resource_constant : "",
      title: this.props.internalization.buttons.add + " "+ item.title,
      associated_elements: this._prepareAddPlusLink(item)
    });
  }

  _restartActionSheet(){
    this._fillActionSheetStart()
  
    // this.setState({ options_action_sheet_short_cut: options_action_sheet_short_cut})
  }

  _error() {
    alert("error");
  }

  _filteredBarsFinancialAction(url) {
    this.props.getFilteredDataBarsGraph(
      this.props.authentication_token,
      url,
      () => this._successFilteredBars(),
      () => this._errorFilteredBars()
    );
  }

  _successFilteredBars() {
    this.setState({
      filteredDataBarsGraph: this.props.filtered_data_bars_graph
    })
  }

  _errorFilteredBars() {
    console.log("error");
  }

  componentDidMount() {
    this.props.getBCIndex(
      this.props.authentication_token,
      () => this._success(),
      () => this._error()
    );
  }

  render() {
    console.log("PROPS", this.props)
    return (
      <div className="main-wrapper">
        {this.state.loading && <Loading text={this.props.internalization.loading}  show={this.state.loading} />}
        {Object.keys(this.props.balance_index).length > 0 && (
          <>
            <StackHeader
              title={this.props.balance_index.tree_graphic_data.title}
              icon={<Icon name="Balance" iconBig={true} />}
              help={false}
              helpAction={() => alert("boton de info del stackHeader")}
            />
            <ContentBlock
              title={this.props.balance_index.tree_graphic_data.title}
              icon={<Icon name="Balance" iconBig={true} />}
              className="centered-and-full"
              help={false}
              helpAction={() => alert("Net Worth Summary action")}
              button={true}
              buttonAction={() => this.actionSheet.current.show()}
              buttonText={this.props.balance_index.tree_graphic_data.buttons.add}
              header={false}
            >
              {
                (true) && Object.keys(this.props.balance_index.tree_graphic_data).length > 0 && (this.props.balance_index.tree_graphic_data.counters.active != "0" || this.props.balance_index.tree_graphic_data.counters.pasive != "0") &&
                  <TreeGraphic
                    data={this.props.balance_index.tree_graphic_data}
                    history={this.props.history}
                    add_translation={this.props.internalization.buttons.add}
                    positive_balance={this.props.balance_index.tree_graphic_data.positive_balance}
                    total={this.props.balance_index.tree_graphic_data.total}
                    canManage = {true}
                    from={"BalanceIndexScreen"}
                    language={this.props.user.language}
                    permits={this.props.permits}
                  />                
              }

              {/*
                  !canRead(this.props.permits, 'rest_assets') && !canRead(this.props.permits, 'financial_assets') && 
                      <div className="content-fake">
                          <div className="content-fake__icon">
                              <Icon name="Lock" color="#005774"/>
                              <p className="headingH4">Not granted</p>
                          </div>
                          <img src={treeGraphic_fake} className="content-fake__image"/>
                      </div>
              */}
              {
                  (canRead(this.props.permits, 'rest_assets') || canRead(this.props.permits, 'financial_assets'))  && Object.keys(this.props.balance_index.tree_graphic_data).length > 0 && (this.props.balance_index.tree_graphic_data.type.active.length == 0 && this.props.balance_index.tree_graphic_data.type.pasive.length == 0) &&
                  <span style={{lineHeight: 16}}>No data yet</span>  
              }              
              {/* <NoResources text="No assets yet" button={true} btnText="add new asset" class="height" buttonAction={() => {}}/> */}
            </ContentBlock>            
              <ContentBlock
                anchor={"net_worth_evolution"}
                title={this.props.balance_index.bars_graphic_data.title}
                header={true}
                className="centered-and-full"
                help={false}
                balance={true}
                maxHeight={false}
              >
                {
                  (true) &&
                    <BarsGraphic
                      data={
                        Object.keys(this.props.filtered_data_bars_graph).length > 0
                          ? this.props.filtered_data_bars_graph
                          : this.props.balance_index.bars_graphic_data
                      }
                      filterAction={url => this._filteredBarsFinancialAction(url)}
                      xTickValues={
                        Object.keys(this.props.filtered_data_bars_graph).length > 0
                          ? this.props.filtered_data_bars_graph.type.pasive.map(
                              elem => elem.x
                            )
                          : this.props.balance_index.bars_graphic_data.type.pasive.map(
                              elem => elem.x
                            )
                      }
                      yTickValues={
                        Object.keys(this.props.filtered_data_bars_graph).length > 0
                          ? this.props.filtered_data_bars_graph.type.pasive.map(
                              elem => elem.y
                            )
                          : this.props.balance_index.bars_graphic_data.type.pasive.map(
                              elem => elem.y
                            )
                      }
                    /> 
                }
                {/*
                    !canRead(this.props.permits, 'rest_assets') && !canRead(this.props.permits, 'financial_assets') && 
                        <div className="content-fake">
                          <div className="content-fake__icon">
                              <Icon name="Lock" color="#005774"/>
                              <p className="headingH4">Not granted</p>
                          </div>
                          <img src={barsGraphic_fake} className="content-fake__image"/>
                      </div> 
                */}

              </ContentBlock>
              <div className="super-wrapper-content-block">
                <ContentBlock title={this.props.balance_index.pie_graphic_assets.name} header={true} maxHeight={false}>
                    <PieHighcharts
                      titleResult={"Total:"}
                      title={" " +this.props.balance_index.pie_graphic_assets.total}                    
                      key={"_"+this.props.balance_index.pie_graphic_assets.name}
                      showTotal={true}
                      data={this.props.balance_index.pie_graphic_assets.data}
                      innerRadius={90}
                      centerLabel={true}
                      colorScale={this.state.pieAssetsColorScale}
                      history={this.props.history}
                      from="balance-index"
                    />
                  }
                  {/*
                    !canRead(this.props.permits, 'rest_assets') &&
                    <div className="content-fake">
                          <div className="content-fake__icon">
                              <Icon name="Lock" color="#005774"/>
                              <p className="headingH4">Not granted</p>
                          </div>
                          <img src={pieGraphick_fake} className="content-fake__image"/>
                      </div>
                  */}
                  
                </ContentBlock>
                <ContentBlock
                  title={this.props.balance_index.pie_graphic_liabilities.name}
                  header={true}
                  maxHeight={false}
                >

                    <PieHighcharts
                      titleResult={"Total:"}
                      title={" " +this.props.balance_index.pie_graphic_liabilities.total}
                      showTotal={true}
                      data={this.props.balance_index.pie_graphic_liabilities.data}
                      innerRadius={90}
                      centerLabel={true}
                      colorScale={this.state.pieLiabilitiesColorScale}
                      history={this.props.history}
                      from="balance-index"
                    />
                  

                  {/*
                    !canRead(this.props.permits, 'rest_assets') &&
                    <div className="content-fake">
                          <div className="content-fake__icon">
                              <Icon name="Lock" color="#005774"/>
                              <p className="headingH4">Not granted</p>
                          </div>
                          <img src={pieGraphick_fake} className="content-fake__image"/>
                      </div>
                  */}
                </ContentBlock>
              </div>
          </>
        )}

          <ActionSheet
          ref={this.actionSheet}
          render={this.state.options_action_sheet_short_cut}
          />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    balance_index: state.balance.balance_index,
    authentication_token: state.session.authentication_token,
    filtered_data_bars_graph: state.home.filtered_data_bars_graph,
    permits: state.session.permits,
    user: state.session.user,
    internalization: state.session.internalization,
    bulltick_insurance_agency: state.session.bulltick_insurance_agency,
    real_user_insurance: state.session.real_user_insurance
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getBCIndex: (authentication_token, success, error) =>
      dispatch(getBCIndex(authentication_token, success, error)),
    getFilteredDataBarsGraph: (authentication_token, url, success, error) =>
      dispatch(
        getFilteredDataBarsGraph(authentication_token, url, success, error)
      ),
    setCurrentBreadcrumb: (current_bradcrumb) => dispatch(setCurrentBreadcrumb(current_bradcrumb))    

  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BalanceIndexScreen);
